import { ComponentToPrint } from './ComponentToPrint';
import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';

function Preview({ item }) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log("INI PROPS", item)

  return (
    <div>

      <button
        // className="btn btn-info btn-sm rounded-0"
        type="button"
        title="Print"
        // onClick={handlePrint}
        onClick={handleShow}
      >
        <i className="fa fa-print" />
      </button>

      <Modal fullscreen={true} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body><ComponentToPrint item={item} ref={componentRef} /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <button
            className="btn btn-info btn-sm rounded-0"
            type="button"
            title="Print"
            onClick={handlePrint}
          >
            <i className="fa fa-print" />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Preview;



