import React, { Component } from 'react';
import { DataGrid, GridColumn, Layout, LayoutPanel } from 'rc-easyui';
import parse from "html-react-parser";
import axios from 'axios';
import { uAPIlocal } from '../../lib/config';
import Loader from 'react-loader-spinner';
import Barchart from '../../chart/BarChart';
import PieChart from '../../chart/PieChart';

class All extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all_conc: {
                data: [],
                selection: null,
                loading: true,
            },
            all_patient: null,
            result_grafik: null,
        }
        this.getData = this.getData.bind(this);
        this.selectionChange = this.selectionChange.bind(this);
    }
    async getData() {
        var id_company = localStorage.getItem("Company_ID");
        await axios.get(uAPIlocal + '/api/v1/all_conc',{ params: { Company_ID: id_company  } })
            .then(function (response) {
                return response.data.results;
            })
            .then(response => {
                // console.log(response)
                this.setState({
                    all_conc: {
                        ...this.state.all_conc,
                        data: response,
                        loading: false
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async selectionChange(event) {
        // console.log(event)
        await this.setState({
            all_conc: {
                ...this.state.all_conc,
                selection: event
            },
            all_patient: {
                data: [],
                loading: true,
            },
            result_grafik: true,
            resultGraphicAll: true,
            resultGraphicConc: true,
        })
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        formData.append('Conc_Name', this.state.all_conc.selection.Conc_Name);
        formData.append('Company_ID',localStorage.getItem("Company_ID"));

        await axios.post(uAPIlocal + '/api/v1/result_grafik_all', formData, config)
            .then(function (response) {
                return response.data;
            })
            .then(response => {
                // console.log(response)
                const grafik = response.grafik;
                // const keterangan = response.keterangan;
                this.setState({
                    grafik: {
                        labels: grafik.kesimpulan,
                        datasets: [
                            {
                                label: "2023",
                                backgroundColor: 'purple',
                                borderColor: 'rgba(0,0,0,1)',
                                borderWidth: 1,
                                data: grafik.a
                            },
                            {
                                label: "2022",
                                backgroundColor: 'green',
                                borderColor: 'rgba(0,0,0,1)',
                                borderWidth: 1,
                                data: grafik.b
                            }, {
                                label: "2021",
                                backgroundColor: 'blue',
                                borderColor: 'rgba(0,0,0,1)',
                                borderWidth: 1,
                                data: grafik.c
                            }, {
                                label: "2020",
                                backgroundColor: 'red',
                                borderColor: 'rgba(0,0,0,1)',
                                borderWidth: 1,
                                data: grafik.d
                            },
                            {
                                label: "2019",
                                backgroundColor: 'black',
                                borderColor: 'rgba(0,0,0,1)',
                                borderWidth: 1,
                                data: grafik.e
                            }
                        ]
                    },
                    options: {
                        animation: false,
                        layout: {
                            padding: 5
                        },
                        // legend: {
                        //     display: false
                        // },
                        responsive: true,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }],
                            xAxes: [{
                                ticks: {
                                    fontSize: 10,
                                    minRotation: 90
                                }
                            }]
                        }
                    },
                    resultGraphicAll: false,
                })
            })
            .catch(function (error) {
                console.log(error);
            });

        await axios.post(uAPIlocal + '/api/v1/result_grafik_all_conc', formData, config)
            .then(function (response) {
                return response.data;
            })
            .then(response => {
                const grafik = response.grafik;
                const keterangan = response.keterangan;
                this.setState({
                    grafik2: {
                        labels: ["NORMAL", "ABNORMAL"],
                        datasets: [
                            {
                                data: [grafik.normal, grafik.abnormal],
                                backgroundColor: [
                                    "orange",
                                    "yellow",
                                ],
                                hoverBackgroundColor: [
                                    "orange",
                                    "yellow",
                                ]
                            }
                        ]
                    },
                    options2: {
                        animation: false,
                        responsive: true,
                        hover: {
                            mode: 'label',
                        },
                        title: {
                            display: true,
                            text: keterangan
                        },
                        tooltips: {
                            enabled: true,
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var label = data.labels[tooltipItem.index];
                                    var val = data.datasets[tooltipItem.datasetIndex].data[
                                        tooltipItem.index];

                                    var dataset = data.datasets[tooltipItem.datasetIndex];
                                    var total = dataset.data.reduce(function (previousValue,
                                        currentValue, currentIndex, array) {
                                        return Math.floor(previousValue +
                                            currentValue);
                                    });
                                    var currentValue = dataset.data[tooltipItem.index];
                                    var precentage = ((currentValue / total) * 100);
                                    return label + ' : ' + val + ' (' + precentage.toFixed(
                                        2) + '%)';

                                }
                            }

                        }
                    },
                    resultGraphicConc: false,
                })
            })
            .catch(function (error) {
                console.log(error);
            });

        await axios.post(uAPIlocal + '/api/v1/all_patient', formData, config)
            .then(function (response) {
                return response.data.results;
            })
            .then(results => {
                this.setState({
                    all_patient: {
                        data: results,
                        loading: false,
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount() {
        this.getData();
    }
    render() {
        return (
            <div className='pd-20'>
                <Layout style={{ width: '100%', height: 600 }} >
                    <LayoutPanel region="west" title="Conclusion" split collapsible expander style={{ width: '20%', }}>
                        <DataGrid filterable filterRules={[]} {...this.state.all_conc} style={{ height: '100%', }} selectionMode='single' selection={this.state.all_conc.selection} onSelectionChange={this.selectionChange} >
                            <GridColumn field="rn" align="center" width='30px'
                                cellCss="datagrid-td-rownumber" filterable={false}
                                render={({ rowIndex }) => (
                                    <span>{rowIndex + 1}</span>
                                )}
                            />
                            <GridColumn field="Conc_Name" title="Conclusion Name" align="center" sortable
                                render={({ row }) => {
                                    return parse(`
                                        <div style="text-align: 'center'; white-space: normal;">
                                            ${row.Conc_Name ? (row.Conc_Name) : ('')}
                                        </div>
                                    `)
                                }}
                            />
                        </DataGrid>
                    </LayoutPanel>
                    <LayoutPanel region="center" title="Totality Graphic" split style={{ height: '100%', }}>
                        {
                            this.state.result_grafik ? (
                                this.state.resultGraphicAll ? (
                                    <div className="text-center pd-90" >
                                        <Loader type="Bars" color="#00BFFF" height={60} width={100} />
                                            Loading ...
                                    </div>
                                ) : (
                                        this.state.grafik ? (
                                            <Barchart type='bar' data={this.state.grafik} options={this.state.options} />
                                        ) : null
                                    )
                            ) : null
                        }
                    </LayoutPanel>
                    <LayoutPanel region="east" title="Conclusion Graphic" split collapsible expander style={{ width: '20%', }}>
                        {
                            this.state.result_grafik ? (
                                this.state.resultGraphicConc ? (
                                    <div className="text-center pd-90" >
                                        <Loader type="Bars" color="#00BFFF" height={60} width={100} />
                                            Loading ...
                                    </div>
                                ) : (
                                        this.state.grafik2 ? (
                                            <PieChart data={this.state.grafik2} options={this.state.options2} />
                                        ) : null
                                    )
                            ) : null
                        }
                    </LayoutPanel>
                    <LayoutPanel region="south" title="Patient" split collapsible expander style={{ width: '100%', height: 250 }}>
                        {
                            this.state.all_patient ? (
                                <DataGrid {...this.state.all_patient} style={{ height: '100%', }} >
                                    <GridColumn field="rn" align="center" width='30px'
                                        cellCss="datagrid-td-rownumber"
                                        render={({ rowIndex }) => (
                                            <span>{rowIndex + 1}</span>
                                        )}
                                    />
                                    <GridColumn field="Patient_NIK" title="NIK" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row.Patient_NIK ? (row.Patient_NIK) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="Patient_id" title="PID" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row.Patient_id ? (row.Patient_id) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="Patient_name" title="Patient Name" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row.Patient_name ? (row.Patient_name) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="Patient_DOB" title="DOB" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row.Patient_DOB ? (row.Patient_DOB) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="Patient_Gender" title="Gender" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row.Patient_Gender ? (row.Patient_Gender) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                     <GridColumn field="2023" title="2023" align="center" width='60px' sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2023] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="2022" title="2022" align="center" width='60px' sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2022] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="2021" title="2021" align="center" width='60px' sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2021] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="2020" title="2020" align="center" width='60px' sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2020] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                     <GridColumn field="2019" title="2019" align="center" width='60px' sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2019] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    {/* <GridColumn field="2017" title="2017" align="center" width='60px'
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2017] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                        }}
                                    /> */}
                                </DataGrid>
                            ) : null
                        }
                    </LayoutPanel>
                </Layout>
            </div>
        )
    }
}

export default All;