import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="main-footer" >
                <div className="container-fluid">
                    <span style={{ borderRadius: '20px', width:'50%', padding:'8px', color:'rgb(15, 71, 62)'}}>Copyright © 2020 <Link to="#">PT. BIO MEDIKA MANDIRI JAYA & PT. BIO CORE</Link>. All rights reserved.</span>
                </div>
            </div>
        )
    }
}
export default Footer;