import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { withTranslation } from 'react-i18next';

class Barchart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      data: this.props.data,
      options: this.props.options,
      legend: [],
    }
  }
  componentDidMount() {
    this.props.i18n.changeLanguage(localStorage.getItem("lang"));
    const component = this.doughnut;
    this.setState({ legend: component.chartInstance.legend.legendItems });
  }

  chartHover(index) {
    var chart = this.doughnut.chartInstance;
    var segment = chart.getDatasetMeta(0).data[index];
    chart.tooltip._active = [segment];
    chart.tooltip.update();
    chart.updateHoverStyle([segment], null, true)
    chart.render();
  }


  chartLeave(index) {
    var chart = this.doughnut.chartInstance;
    var segment = chart.getDatasetMeta(0).data[index];
    chart.tooltip._active = [];
    chart.tooltip.update();
    chart.updateHoverStyle([segment], null, false)
    chart.render();
  }

  render() {
    const { legend } = this.state;


    return (
      <>
        <Row style={{ height: "100px" }}>
          <Col md={7} className="doughnutChart">

            <Doughnut
              ref={ref => (this.doughnut = ref)}
              data={this.state.data}
              options={{
                maintainAspectRatio: false,
                // cutoutPercentage: 60,
                legend: {
                  display: false,
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      display: false,
                      beginAtZero: true
                    },
                    gridLines: {
                      display: false,
                    }

                  }],
                  xAxes: [{
                    gridLines: {
                      display: false
                    },
                    ticks: {
                      display: false,
                      beginAtZero: true
                    },

                  }],

                },
                plugins: {
                  ChartDataLabels,
                  datalabels: {
                    // display: false,
                    // formatter: function(value, context) {
                    //   return context.chart.data.labels[context.dataIndex];
                    // },
                    formatter: function (val, context) {
                      return `${((Number(val) * 100) /
                        context.chart.data.datasets[context.datasetIndex].data.reduce(
                          (a, b) => Number(a) + Number(b),
                          0
                        )).toFixed(2)
                        }%`
                    },
                    color: "white",
                    font: {
                      size: 12,
                      weight: "bold"
                    }
                  }
                }

              }}
              // height={200}
              // width={100}
            // plugins={plugins}
            />

          </Col>


          <Col md={5}>

            <ul className="mt-8 doughnutChartColumn" style={{ marginTop: '40px', marginLeft: '-20px' }}>

              {legend.length &&
                legend.map((item, index) => {
                  if (index > 0) {
                  return (
                    <li className="listFlex_poin" style={{ height: '47px' }}>
                      <div
                      onMouseOver={(e) => this.chartHover(index)} onMouseLeave={(e) => this.chartLeave(index)} 
                        style={{
                          float: 'left',
                          margin: "6px",
                          width: "18px",
                          height: "18px",
                          backgroundColor: item.fillStyle,
                          borderRadius: '50%',
                        }}
                      />

                      <p onMouseOver={(e) => this.chartHover(index)} onMouseLeave={(e) => this.chartLeave(index)} className="doughnutChartLegendText1" style={{}}>{item.text}</p>
                      <p onMouseOver={(e) => this.chartHover(index)} onMouseLeave={(e) => this.chartLeave(index)} className="doughnutChartLegendText2" style={{color: '#0F473E' }}>{this.state.data.datasets[0].data[index]} {this.props.t('orang')}</p>
                    </li>
                  );
                }
                })}

                {legend.length &&
                legend.map((item, index) => {
                  if (index === 0) {
                  return (
                    <li style={{ listStyle: "none", height: '67px' }}>
                      <div
                      onMouseOver={(e) => this.chartHover(index)} onMouseLeave={(e) => this.chartLeave(index)} 
                        style={{
                          float: 'left',
                          margin: "6px",
                          width: "18px",
                          height: "18px",
                          backgroundColor: item.fillStyle,
                          borderRadius: '50%',
                        }}
                      />

                      <p onMouseOver={(e) => this.chartHover(index)} onMouseLeave={(e) => this.chartLeave(index)} className="doughnutChartLegendText1"  style={{}}>{item.text}</p>
                      <p onMouseOver={(e) => this.chartHover(index)} onMouseLeave={(e) => this.chartLeave(index)} className="doughnutChartLegendText2" style={{color: 'red' }}>{this.state.data.datasets[0].data[index]} {this.props.t('orang')}</p>
                    </li>
                  );
                }
                })}

            </ul>

          </Col>



               {/* <Col md={12}>
                 <div className="d-flex justify-content-center" style={{marginTop: '30px', marginLeft:'20px', marginRight: '10px', backgroundColor: 'white', borderRadius: '20px', textAlign: "justify", paddingBottom: '0px', paddingTop:'10px' }}>
                  <Row style={{padding:'20px'}}>

                  <Col md={12}>
                  <p style={{fontSize:'18px', fontFamily:"Montserrat", marginBottom:'0px' }}><b>Notes:</b></p>
                  </Col>
                   <Col md={12}>
                   <p style={{fontSize:'18px', fontFamily:"Montserrat" }}>Hasil temuan Normal/Abnormal dari total peserta MCU</p>
                  </Col>

                  </Row>
                 </div>
                </Col>  */}




        </Row>





      </>

      // <canvas ref={this.canvasRef} />
    )
  }
}
export default withTranslation()(Barchart);