import React, { Component } from 'react';
import axios from 'axios';
import { uAPIlocal } from '../../lib/config';

class JumlahTotal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jumlah_total: {
                total: 0,
                normal: 0,
                abnormal: 0,
            }
        }
        this.getData = this.getData.bind(this);
    }
    async getData() {
        const date = new Date();
        const set1yearago = 1;
        const set2yearago = 2;
        const thisyear = date.getFullYear();
        const setoneyearago = date.setFullYear(thisyear - set1yearago);
        const settwoyearago = date.setFullYear(thisyear - set2yearago);
        const oneyearago = new Date(setoneyearago).getFullYear();
        const twoyearago = new Date(settwoyearago).getFullYear();
        var id_company = localStorage.getItem("Company_ID");
        await axios.get(uAPIlocal + '/api/v1/jumlah_total',{ params: { Company_ID: id_company  } })
            .then(function (response) {
                return response.data.results;
            })
            .then(response => {
                this.setState({
                    jumlah_total: {
                        thisyear: thisyear,
                        total2023: response.total2023,
                        normal2023: response.normal2023,
                        abnormal2023: response.abnormal2023,
                        total2022: response.total2022,
                        normal2022: response.normal2022,
                        abnormal2022: response.abnormal2022,
                        total2021: response.total2021,
                        normal2021: response.normal2021,
                        abnormal2021: response.abnormal2021,
                        oneyearago: oneyearago,
                        total2020: response.total2020,
                        normal2020: response.normal2020,
                        abnormal2020: response.abnormal2020,
                        twoyearago: twoyearago,
                        total2019: response.total2019,
                        normal2019: response.normal2019,
                        abnormal2019: response.abnormal2019,
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount() {
        this.getData();
    }
    render() {

        return (
            <div className='pd-20'>
                <div className='row'>
                <div className='col-sm-3'>
                        <h1>2023</h1>
                        <h2>Pasien Total = {this.state.jumlah_total.total2023}</h2>
                        <h2>Pasien Normal = {this.state.jumlah_total.normal2023}</h2>
                        <h2>Pasien Abnormal = {this.state.jumlah_total.abnormal2023}</h2>
                    </div>
                <div className='col-sm-3'>
                        <h1>2022</h1>
                        <h2>Pasien Total = {this.state.jumlah_total.total2022}</h2>
                        <h2>Pasien Normal = {this.state.jumlah_total.normal2022}</h2>
                        <h2>Pasien Abnormal = {this.state.jumlah_total.abnormal2022}</h2>
                    </div>
                    <div className='col-sm-3'>
                        <h1>2021</h1>
                        <h2>Pasien Total = {this.state.jumlah_total.total2021}</h2>
                        <h2>Pasien Normal = {this.state.jumlah_total.normal2021}</h2>
                        <h2>Pasien Abnormal = {this.state.jumlah_total.abnormal2021}</h2>
                    </div>
                    <div className='col-sm-3'>
                        <h1>2020</h1>
                        <h2>Pasien Total = {this.state.jumlah_total.total2020}</h2>
                        <h2>Pasien Normal = {this.state.jumlah_total.normal2020}</h2>
                        <h2>Pasien Abnormal = {this.state.jumlah_total.abnormal2020}</h2>
                    </div>
                    <div className='col-sm-3'>
                        <h1>2019</h1>
                        <h2>Pasien Total = {this.state.jumlah_total.total2019}</h2>
                        <h2>Pasien Normal = {this.state.jumlah_total.normal2019}</h2>
                        <h2>Pasien Abnormal = {this.state.jumlah_total.abnormal2019}</h2>
                    </div>
                </div>

            </div>
        )
    }
}

export default JumlahTotal;