import React, { Component } from 'react';
// import { patient } from '../../DummyData';
import { DataGrid, GridColumn, Layout, LayoutPanel } from 'rc-easyui';
import axios from 'axios';
import { uAPIlocal } from '../../lib/config';
import parse from "html-react-parser";
import Loader from 'react-loader-spinner';
import Barchart from '../../chart/BarChart';

function thousand_separator(input) {
    var number = input.split('.');
    var num = number[0];
    num = num.split("").reverse().join("");
    var numpoint = '';
    for (var i = 0; i < num.length; i++) {
        numpoint += num.substr(i, 1);
        if (((i + 1) % 3 === 0) && i !== num.length - 1) {
            numpoint += ',';
        }
    }
    num = numpoint.split("").reverse().join("");
    if (number[1] !== undefined) {
        num = num + '.' + number[1];
    }
    return num;
}

class Patient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: {
                total: 0,
                pageSize: 20,
                data: [],
                pagePosition: "bottom",
                pageOptions: {
                    layout: ['list', 'sep', 'first', 'prev', 'next', 'last', 'sep', 'refresh', 'sep', 'manual', 'info']
                },
                selection: null,
                loading: true,
            },
            result: null,
            result_conc: null,
            result_grafik: null,
        }
        this.getData = this.getData.bind(this);
        // this.loadData = this.loadData.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.selectionChange = this.selectionChange.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.selectionInfo = this.selectionInfo.bind(this);
        this.selectionChangeRes = this.selectionChangeRes.bind(this);
    }
    async getData() {
        var id_company = localStorage.getItem("Company_ID");
        await axios.get(uAPIlocal + '/api/v1/patient',{ params: { Company_ID: id_company  } })
            .then(function (response) {
                return response.data.results;
            })
            .then(response => {
                // console.log(response)
                this.setState({
                    patient: {
                        ...this.state.patient,
                        data: response,
                        loading: false
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    pageChange(event) {
        console.log(event)
        if (event.refresh) {
            // console.log(event)
            this.setState({
                patient: {
                    ...this.state.patient,
                    loading: true,
                    selection: null
                },
                result: null,
                result_conc: null,
                result_grafik: null,
            })
            this.getData();
        }

    }

    filterChange(event) {
        console.log(event)
        // this.selectionChange(event)
        // this.getData();
    }
    async selectionChange(event) {
        console.log(event)
        await this.setState({
            patient: {
                ...this.state.patient,
                selection: event
            },
            result: {
                // ...this.state.result,
                data: [],
                loading: true,
            },
            result_conc: {
                // ...this.state.result_conc,
                data: [],
                loading: true,
            },
            result_grafik: null,
            // resultGraphic: true,
        })
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        formData.append('Patient_id', this.state.patient.selection.Patient_id);
        formData.append('Company_ID',localStorage.getItem("Company_ID"));
        // formData.append('Patient_name', this.state.patient.selection.Patient_name);
        // formData.append('Patient_NIK', this.state.patient.selection.Patient_NIK);
        // formData.append('Patient_DOB', this.state.patient.selection.Patient_DOB);
        // formData.append('Patient_Gender', this.state.patient.selection.Patient_Gender);
        // formData.append('Patient_Dept', this.state.patient.selection.Patient_Dept);
        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ': ' + pair[1]);
        // }
        await axios.post(uAPIlocal + '/api/v1/result', formData, config)
            .then(function (response) {
                return response.data.results;
            })
            .then(results => {
                console.log(results)
                this.setState({
                    result: {
                        data: results,
                        loading: false,
                        selection: null,
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });

        await axios.post(uAPIlocal + '/api/v1/result_conc', formData, config)
            .then(function (response) {
                return response.data.results;
            })
            .then(results => {
                // console.log(results)
                this.setState({
                    result_conc: {
                        data: results,
                        loading: false,
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    selectionInfo() {
        const { selection } = this.state.patient;
        if (!selection) {
            return null;
        } else {
            return `{ Patient_id: ${selection.Patient_id}, Patient_name: ${selection.Patient_name}, Patient_NIK: ${selection.Patient_NIK}, Patient_DOB: ${selection.Patient_DOB}, Patient_Gender: ${selection.Patient_Gender}, Patient_Dept: ${selection.Patient_Dept} }`;
        }


    }
    async selectionChangeRes(event) {
        console.log(event)
        await this.setState({
            result: {
                ...this.state.result,
                selection: event
            },
            result_grafik: true,
            resultGraphic: true,
        })
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        // formData.append('2017', this.state.result.selection[2017]);
        // formData.append('2018', this.state.result.selection[2018]);
        // formData.append('2019', this.state.result.selection[2019]);
        formData.append('Patient_ID', this.state.result.selection.Patient_ID);
        formData.append('Test_ID', this.state.result.selection.Test_ID);
        // formData.append('F2019', this.state.result.selection.F2019);
        // formData.append('R2019', this.state.result.selection.R2019);
        // formData.append('N2019', this.state.result.selection.N2019);
        // formData.append('U2019', this.state.result.selection.U2019);
        // formData.append('F2017', this.state.result.selection.F2017);
        // formData.append('R2017', this.state.result.selection.R2017);
        // formData.append('N2017', this.state.result.selection.N2017);
        // formData.append('U2017', this.state.result.selection.U2017);
        // formData.append('F2018', this.state.result.selection.F2018);
        // formData.append('R2018', this.state.result.selection.R2018);
        // formData.append('N2018', this.state.result.selection.N2018);
        // formData.append('U2018', this.state.result.selection.U2018);
        formData.append('Test_Name', this.state.result.selection.Test_Name);
        // formData.append('Test_Seq', this.state.result.selection.Test_Seq);
        // formData.append('Test_Group', this.state.result.selection.Test_Group);
        // formData.append('Test_Group_Seq', this.state.result.selection.Test_Group_Seq);
        // formData.append('Test_Graph', this.state.result.selection.Test_Graph);
        formData.append('Company_ID',localStorage.getItem("Company_ID"));

        await axios.post(uAPIlocal + '/api/v1/result_grafik', formData, config)
            .then(function (response) {
                return response.data;
            })
            .then(response => {
                const grafik = response.grafik;
                const keterangan = response.keterangan;
                this.setState({
                    grafik: {
                        labels: grafik.kesimpulan,
                        datasets: [
                            {
                                label: 'Jumlah',
                                data: grafik.jumlah,
                                backgroundColor: '#59e1d0',
                            }
                        ]
                    },
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var value = data.datasets[0].data[tooltipItem.index];
                                    return thousand_separator(value);
                                }
                            }
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                        title: {
                            display: true,
                            fontSize: 11,
                            text: keterangan
                        }
                    },
                    resultGraphic: false,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount() {
        // this.loadData();
        this.getData();

    }
    render() {
        return (
            <div className='pd-20'>
                <Layout style={{ width: '100%', height: 650 }} >
                    <LayoutPanel region="west" title="Patient" split collapsible expander style={{ width: 600, }}>
                        <DataGrid filterable filterRules={[]} pagination {...this.state.patient} style={{ height: '100%', }} selectionMode='single' selection={this.state.patient.selection} onSelectionChange={this.selectionChange} onPageChange={this.pageChange}
                        // onFilterChange={this.filterChange} 
                        >
                            <GridColumn field="rn" align="center" width='30px'
                                cellCss="datagrid-td-rownumber" filterable={false}
                                render={({ rowIndex }) => (
                                    <span>{rowIndex + 1}</span>
                                )}
                            />
                            {/* <GridColumn filterable={true} field='Patient_NIK' title='NIK' align="center" width='80px'></GridColumn>
                            <GridColumn filterable={true} field='Patient_name' title='NAME' align="center"></GridColumn>
                            <GridColumn filterable={true} field='Patient_Dept' title='DPT' align="center"></GridColumn>
                            <GridColumn filterable={true} field='Patient_id' title='PID' align="center" width='100px'></GridColumn> */}

                            <GridColumn filterable={true} field="Patient_NIK" title="NIK" align="center" width='80px'
                                // filter={() => {
                                //     return(
                                //         <NumberBox></NumberBox>
                                //     )
                                // }}
                                render={({ row }) => {
                                    return parse(`
                                        <div style="text-align: 'center'; white-space: normal;">
                                            ${row.Patient_NIK ? (row.Patient_NIK) : ('')}
                                        </div>
                                    `)
                                }}
                            />
                            <GridColumn filterable={true} field="Patient_name" title="NAME" align="center"
                                render={({ row }) => {
                                    return parse(`
                                        <div style="text-align: 'center'; white-space: normal;">
                                            ${row.Patient_name ? (row.Patient_name) : ('')}
                                        </div>
                                    `)
                                }}
                            />
                            <GridColumn filterable={true} field="Patient_Dept" title="DPT" align="center"
                                render={({ row }) => {
                                    return parse(`
                                        <div style="text-align: 'center'; white-space: normal;">
                                            ${row.Patient_Dept ? (row.Patient_Dept) : ('')}
                                        </div>
                                    `)
                                }}
                            />
                            <GridColumn filterable={true} field="Patient_id" title="PID" align="center" width='100px'
                                render={({ row }) => {
                                    return parse(`
                                        <div style="text-align: 'center'; white-space: normal;">
                                            ${row.Patient_id ? (row.Patient_id) : ('')}
                                        </div>
                                    `)
                                }}
                            />
                        </DataGrid>
                    </LayoutPanel>
                    <LayoutPanel region="center" title="Result" split style={{ height: '100%' }}>

                        {
                            this.state.result ? (
                                // <div>
                                //     <p>Test selected: {this.selectionInfo()}</p>

                                <DataGrid {...this.state.result} style={{ height: '100%', }} selectionMode='single' selection={this.state.result.selection} onSelectionChange={this.selectionChangeRes}  >
                                    <GridColumn field="rn" align="center" width='30px'
                                        cellCss="datagrid-td-rownumber"
                                        render={({ rowIndex }) => (
                                            <span>{rowIndex + 1}</span>
                                        )}
                                    />
                                    <GridColumn field="Test_Name" title="Test Name" align="center" sortable
                                        render={({ row }) => {
                                            // return (
                                            //     <div style={{ textAlign: 'center', whiteSpace: 'normal' }}>
                                            //         {row.Test_Name}
                                            //     </div>
                                            // )
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row.Test_Name ? (row.Test_Name) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="N2023" title="Reference Value" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row.N2023 ? (row.N2023) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="U2023" title="Units" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row.U2023 ? (row.U2023) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                   
                                    <GridColumn field="2023" title="2023" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2023] ? (row[2023]) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    
                                    <GridColumn field="N2022" title="Reference Value" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row.N2022 ? (row.N2022) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="U2022" title="Units" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row.U2022 ? (row.U2022) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                   
                                    <GridColumn field="2022" title="2022" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2022] ? (row[2022]) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="2021" title="2021" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2021] ? (row[2021]) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    <GridColumn field="2020" title="2020" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2020] ? (row[2020]) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />

                                <GridColumn field="2019" title="2019" align="center" sortable
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2019] ? (row[2019]) : ('')}
                                                </div>
                                            `)
                                        }}
                                    />
                                    {/* <GridColumn field="2017" title="2017" align="center"
                                        render={({ row }) => {
                                            return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2017] ? (row[2017]) : ('')}
                                                </div>
                                            `)
                                        }}
                                    /> */}
                                </DataGrid>
                                // </div>
                            ) : null
                        }

                    </LayoutPanel>
                    <LayoutPanel region="south" split style={{ width: '100%', height: 300 }}>
                        <Layout >
                            <LayoutPanel region="west" style={{ width: 600, }}>
                                {
                                    this.state.result_grafik ? (
                                        this.state.resultGraphic ? (
                                            <div className="text-center pd-90" >
                                                <Loader type="Bars" color="#00BFFF" height={60} width={100} />
                                            Loading ...
                                            </div>
                                        ) : (
                                                this.state.grafik ? (
                                                    <Barchart type='bar' data={this.state.grafik} options={this.state.options} />
                                                ) : null
                                            )
                                    ) : null
                                }
                            </LayoutPanel>
                            <LayoutPanel region="center" split>
                                {
                                    this.state.result_conc ? (
                                        <DataGrid {...this.state.result_conc} style={{ height: 300, }}  >
                                            <GridColumn field="rn" align="center" width='30px'
                                                cellCss="datagrid-td-rownumber"
                                                render={({ rowIndex }) => (
                                                    <span>{rowIndex + 1}</span>
                                                )}
                                            />
                                            {/* <GridColumn field="Conc_Name" title="Conclusion Name" width='150px'></GridColumn> */}
                                            {/* <GridColumn field="2019" title="2019" width='60px'></GridColumn>
                                            <GridColumn field="2018" title="2018" width='60px'></GridColumn>
                                            <GridColumn field="2017" title="2017" width='60px'></GridColumn> */}

                                            <GridColumn field="Conc_Name" title="Conclusion Name" align="center" width='150px' sortable
                                                render={({ row }) => {
                                                    return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row.Conc_Name ? (row.Conc_Name) : ('')}
                                                </div>
                                            `)
                                                }}
                                            />

                                            <GridColumn field="2023" title="2023" align="center" width='60px' sortable
                                                render={({ row }) => {
                                                    return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2023] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                                }}
                                            />

                                            <GridColumn field="2022" title="2022" align="center" width='60px' sortable
                                                render={({ row }) => {
                                                    return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2022] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                                }}
                                            />
                                            <GridColumn field="2021" title="2021" align="center" width='60px' sortable
                                                render={({ row }) => {
                                                    return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2021] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                                }}
                                            />
                                            <GridColumn field="2020" title="2020" align="center" width='60px' sortable
                                                render={({ row }) => {
                                                    return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2020] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                                }}
                                            />
                                              <GridColumn field="2019" title="2019" align="center" width='60px' sortable
                                                render={({ row }) => {
                                                    return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2019] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                                }}
                                            />
                                            {/* <GridColumn field="2017" title="2017" align="center" width='60px'
                                                render={({ row }) => {
                                                    return parse(`
                                                <div style="text-align: 'center'; white-space: normal;">
                                                    ${row[2017] !== null ? ('✓') : ('')}
                                                </div>
                                            `)
                                                }}
                                            /> */}
                                        </DataGrid>
                                    ) : null
                                }
                            </LayoutPanel>
                        </Layout>

                    </LayoutPanel>
                </Layout>
            </div>
        )
    }
}
export default Patient;