import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

class Barchart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      data: this.props.data,
      options: this.props.options,
      title: this.props.title,
      totalPeserta: this.props.totalPeserta,
      legend: [],
      isBigScreen: false,
    }
    this.resizeScreen = this.resizeScreen.bind(this); 
  }
  componentDidMount() {
    const component = this.pie;
    this.setState({ legend: component.chartInstance.legend.legendItems });
    window.addEventListener("resize", this.resizeScreen());
  }


    resizeScreen() {
      // alert(window.innerWidth);
        if(window.innerWidth >= 1600)
        {
          // alert("true")
            this.setState({isBigScreen:true});
        }else{
          // alert("false")
        }
        
    }

  chartHover(index) {
    var chart = this.pie.chartInstance;
    var segment = chart.getDatasetMeta(0).data[index];
    chart.tooltip._active = [segment];
    chart.options.tooltips.enabled = true;
    chart.tooltip.update();





    chart.updateHoverStyle([segment], null, true)
    chart.render();
  }


  chartLeave(index) {
    var chart = this.pie.chartInstance;
    var segment = chart.getDatasetMeta(0).data[index];
    chart.tooltip._active = [];
    chart.tooltip.update();
    chart.updateHoverStyle([segment], null, false)
    chart.render();
  }

  render() {
    const { legend } = this.state;


    return (
      <>
        {/* <p className={this.state.title != "Normal / Temuan Abnormal" ? "pieChartTitle" : "pieChartTitleLongText"}>{this.state.title}</p> */}

        <Row style={{ height: "100px", marginTop: '-10px' }}>

        <Col md={9}>
        <p style={{marginTop:'20px'}} className={this.state.title != "Normal / Temuan Abnormal" ? "pieChartTitle" : "pieChartTitleLongText"}>{this.state.title}</p>

        </Col>
        <Col md={3}>

        </Col>
          <Col md={9} className='pieChartSubColumn1' style={{}}>
            <div style={{marginTop:'-20px'}}>
            <Pie
              ref={ref => (this.pie = ref)}
              data={this.state.data}
              options={{
                maintainAspectRatio: false,
                // cutoutPercentage: 60,
                legend: {
                  display: false,
                },
                tooltips: {
                  // Disable the on-canvas tooltip
                  enabled: false,


                  callbacks: {



                    label: function (tooltipItem, data) {
                      return data.labels[tooltipItem.index];
                    },
                    afterLabel: function (tooltipItem, data) {
                      return "Jumlah : " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    }

                  },



                  // custom: function (tooltipModel) {
                  //   // Tooltip Element
                  //   var tooltipEl = document.getElementById('chartjs-tooltip');

                  //   // Create element on first render
                  //   if (!tooltipEl) {
                  //     tooltipEl = document.createElement('div');
                  //     tooltipEl.id = 'chartjs-tooltip';
                  //     tooltipEl.innerHTML = '<table></table>';
                  //     tooltipEl.style.backgroundColor = "#000";
                  //     tooltipEl.style.borderColor = "#000000";
                  //     tooltipEl.style.borderWidth = "thin";
                  //     tooltipEl.style.borderStyle = "solid";
                  //     tooltipEl.style.color = "#fff";
                  //     tooltipEl.style.fontWeight = "bold";
                  //     document.body.appendChild(tooltipEl);
                  //   }

                  //   // Hide if no tooltip
                  //   if (tooltipModel.opacity === 0) {
                  //     tooltipEl.style.opacity = 0;
                  //     return;
                  //   }

                  //   // Set caret Position
                  //   tooltipEl.classList.remove('above', 'below', 'no-transform');
                  //   if (tooltipModel.yAlign) {
                  //     tooltipEl.classList.add(tooltipModel.yAlign);
                  //   } else {
                  //     tooltipEl.classList.add('no-transform');
                  //   }

                  //   function getBody(bodyItem) {
                  //     return bodyItem.lines;
                  //   }

                  //   // Set Text
                  //   if (tooltipModel.body) {
                  //     var titleLines = tooltipModel.title || [];
                  //     var bodyLines = tooltipModel.body.map(getBody);

                  //     var innerHtml = '<thead>';

                  //     titleLines.forEach(function (title) {
                  //       innerHtml += '<tr><th>' + title + '</th></tr>';
                  //     });
                  //     innerHtml += '</thead><tbody>';

                  //     bodyLines.forEach(function (body, i) {
                  //       var colors = tooltipModel.labelColors[i];
                  //       var style = 'background:' + colors.backgroundColor;
                  //       style += '; border-color:' + colors.borderColor;
                  //       style += '; border-width: 2px';
                  //       var span = '<span style="' + style + '"></span>';
                  //       innerHtml += '<tr><td>' + span + body + '</td></tr>';
                  //     });
                  //     innerHtml += '</tbody>';

                  //     var tableRoot = tooltipEl.querySelector('table');
                  //     tableRoot.innerHTML = innerHtml;
                  //   }

                  //   // `this` will be the overall tooltip
                  //   var position = this._chart.canvas.getBoundingClientRect();

                  //   // Display, position, and set styles for font
                  //   tooltipEl.style.opacity = 1;
                  //   tooltipEl.style.position = 'absolute';
                  //   tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                  //   tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                  //   tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                  //   tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                  //   tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                  //   tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                  //   tooltipEl.style.pointerEvents = 'none';
                  //   tooltipEl.style.zIndex = 9999;
                  // }
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      display: false,
                      beginAtZero: true
                    },
                    gridLines: {
                      display: false,
                    }

                  }],
                  xAxes: [{
                    gridLines: {
                      display: false
                    },
                    ticks: {
                      display: false,
                      beginAtZero: true
                    },

                  }],

                },
                plugins: {
                  ChartDataLabels,
                  datalabels: {
                    // display: false,
                    // formatter: function(value, context) {
                    //   return context.chart.data.labels[context.dataIndex];
                    // },
                    formatter: function (val, context) {
                      return `${((Number(val) * 100) /
                        context.chart.data.datasets[context.datasetIndex].data.reduce(
                          (a, b) => Number(a) + Number(b),
                          0
                        )).toFixed(2)
                        }%`
                    },
                    color: "white",
                    font: {
                      size: this.state.isBigScreen == true ? 10 : 10,
                      weight: "bold"
                    }
                  }
                }

              }}
              height={200}
            // width={100}
            // plugins={plugins}
            />
            </div>
          </Col>


          <Col md={3} className='pieChartSubColumn2' style={{ margin: '0px', padding: '0px' }}>
            <ul className={"listFlex_B"}>
              {legend.length &&
                legend.map((item, index) => {
                  if (index < 4) {
                    return (
                      <li className="listFlex_poin">
                        <table>
                          <td>
                            <div onMouseOver={(e) => this.chartHover(index)} onMouseLeave={(e) => this.chartLeave(index)}
                              style={{
                                float: 'left',
                                margin: "6px",
                                width: "12px",
                                height: "12px",
                                backgroundColor: item.fillStyle,
                                borderRadius: '50%',
                              }}
                            />
                          </td>
                          <td className="listFlexSub2" style={{ padding: '0px' }}>
                            {/* <div className="pieChartLegend">
                              <p onMouseOver={(e) => this.chartHover(index)} onMouseLeave={(e) => this.chartLeave(index)} style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', marginTop: '6px', textAlign: 'left', marginBottom: '10px', fontSize: '12px', fontFamily: "Montserrat" }}>{item.text.substring(0, 14)}{item.text.length > 14 ? "..." : null}</p>
                            </div> */}
                            <p className='pieChartLegendText' onMouseOver={(e) => this.chartHover(index)} onMouseLeave={(e) => this.chartLeave(index)}  style={{}}>{this.state.title == "Departemen" && item.text == "" ? "MANAGEMENT" : item.text}</p>
                          </td>
                        </table>


                      </li>
                    );
                  }

                })}


            </ul>

            {this.state.title == "Jenis Kelamin" ? (<div id="rectangle-40-new">Total Peserta:<span>{this.state.totalPeserta}</span></div>) : null}


          </Col>







        </Row>











      </>

      // <canvas ref={this.canvasRef} />
    )
  }
}
export default Barchart;