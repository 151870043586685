import React from 'react';
import {
    Switch,
    Route,
    withRouter,
    useRouteMatch,
    useParams
} from "react-router-dom";
import Company from './page-content/company/Company';
import MasterConc from './page-content/admin/MasterConc';
import MasterPatient from './page-content/admin/MasterPatient';
import People from './page-content/People';
import Top10 from './page-content/Top10';
import Kesimpulan from './page-content/Kesimpulan';
import Departement from './page-content/Departement';
import Report from './page-content/Report';

function PostType() {
    let match = useRouteMatch();
    let { postType } = useParams();
    if (postType === "people") {
        localStorage.setItem("path", "PESERTA");
    }else if (postType === "report") { 
        localStorage.setItem("path", "LAPORAN");
    } else {
        localStorage.setItem("path", postType.toUpperCase());
    }

    if (postType === 'kesimpulan') {
        return (
            <Switch>
                <Route path={match.path}>
                    <Kesimpulan />
                </Route>
            </Switch>
        );
    }
    if (postType === 'people') {
        return (
            <Switch>
                <Route path={match.path}>
                    <People />
                </Route>
            </Switch>
        );
    }
    // author: Bayu
    if (postType === 'departemen') {
        return (
            <Switch>
                <Route path={match.path}>
                    <Departement />
                </Route>
            </Switch>
        );
    }
    // end
    if (postType === 'top10') {
        return (
            <Switch>
                <Route path={match.path}>
                    <Top10 />
                </Route>
            </Switch>
        );
    }
    if (postType === 'company') {
        return (
            <Switch>
                <Route path={match.path}>
                    <Company />
                </Route>
            </Switch>
        );
    } if (postType === 'master-conc') {
        return (
            <Switch>
                <Route path={match.path}>
                    <MasterConc />
                </Route>
            </Switch>
        );
    }  if (postType === 'master-patient') {
        return (
            <Switch>
                <Route path={match.path}>
                    <MasterPatient />
                </Route>
            </Switch>
        );
    }  if (postType === 'report') {
        return (
            <Switch>
                <Route path={match.path}>
                    <Report />
                </Route>
            </Switch>
        );
    }else {
        return <h3>Not Found</h3>;
    }
}

export default withRouter(PostType);