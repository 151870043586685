import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import Admin from './components/Admin';
import Login from './components/Welcome';
import sheet from './components/lib/SheetJs'
import ResetPass from "./components/ResetPassword.js";
function Router() {
    return (
        <BrowserRouter>
            {/* <Route path="/" exact component={App} /> */}
            <Route path="/" exact component={Login} />
            <Route path="/admin" component={Admin} />
            <Route path="/sheetjs" component={sheet} />
            <Route path="/reset-password" component={ResetPass} />
        </BrowserRouter>
    );
}


export default Router;