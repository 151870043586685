import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import './LoginPage.css';
// import "./assets/login.scss";
import Loader from "react-loader-spinner";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { uAPIlocal } from './lib/config';
// import { uAPI, IP } from "./config/Config";
// import logo from "./images/bio_logo.png";
// import bg316 from "./images/bg316.png";
import queryString from "query-string";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
// import CryptoJS from "crypto-js";
// import { useCookies } from "react-cookie";

import {
 loadCaptchaEnginge,
 LoadCanvasTemplate,
 LoadCanvasTemplateNoReload,
 validateCaptcha,
} from "react-simple-captcha";
import { v4 as uuidv4 } from "uuid";
import frame2 from "../assets/frame2.svg";
import logo from "../assets/logo.svg";
import frame1 from "../assets/frame1.svg"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Cookies from 'js-cookie';


export default function Login() {
    // _csrfPart
    const instance = axios.create({
        // withCredentials: true,
        withCredentials: false,
    });

    const location = useHistory().location;
    const query = queryString.parse(location.search);
    const history = useHistory();

    // const [rememberMe, setRememberMe] = React.useState();
    // const [login, setLogin] = React.useState(
    //     localStorage.rememberMe ? true : false
    // );
    // const [detail_login, setDetail_login] = React.useState(
    //     localStorage.rememberMe
    //         ? JSON.parse(localStorage.getItem("detail_login"))
    //         : null
    // );

    const [login, setLogin] = React.useState(false);
    const [detail_login, setDetail_login] = React.useState(null);

    const [hidden, setHidden] = React.useState(true);
    const [loader, setLoader] = React.useState(false);
    const [login_alert, setLogin_alert] = React.useState(
        history.location.login_alert ? history.location.login_alert.msg : null
    );

    const [captcha, setCaptcha] = React.useState(false);
    const [loginNotif, setLoginNotif] = React.useState(false);
    const [recentUser, setRecentUser] = React.useState();
    const [statusAlert, setStatusAlert] = React.useState(
        history.location.status ? history.location.status : null
    );
    // const [loginSession, setLoginSession] = React.useState();

    const INITIAL_STATE = {
        email_user: "",
        password: "",
    };

    const [state, setState] = React.useState(INITIAL_STATE);
    const { email_user, password } = state;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [displayReset, setDisplayReset] = React.useState("block");
    const [overlay, setOverlay] = React.useState("none");
    const [CSRFToken, setCSRFToken] = React.useState();
    //  const [cookies, setCookie] = useCookies();
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
    });

    const loginError = async (msg) => {
        await swalWithBootstrapButtons
            .fire({
                icon: "error",
                title: msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            })
            .then(function () {
                setLoader(false);
            });
    };

    const loginSuccess = async (msg) => {
        await swalWithBootstrapButtons
            .fire({
                icon: "success",
                title: msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            })
            .then(function () {
                setLoader(false);
                setLogin(true);
            });
    };

    const swalSuccess = async (msg) => {
        await swalWithBootstrapButtons.fire({
            icon: "success",
            title: msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
        });
    };

    const [tomail, settomail] = useState("");
    const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    /**
     * sendmail digunakan untuk validasi email yang akan direset
     * dimulai dengan pengecekan format email, membandingkan antara
     * state tomail dan variabel re jika format benar selanjutnya
     * email dikirim untuk validasi
     * apakah email yang diinput valid atau terdaftar?
     */

    async function sendmail() {
        if (re.test(String(tomail)) === false) {
            loginError("Format email salah!");
        } else {
            //  setDisplayReset("none");
            setOverlay("flex");
            await instance
                .post(uAPIlocal + "/api/v1/auth_email", {
                    email_user: tomail.toLowerCase(),
                })
                .then((response) => {
                    return response.data;
                })
                .then((JsonData) => {
                    const status = JsonData.status;
                    if (status !== 200) {
                        loginError(JsonData.results);
                        setDisplayReset("block");
                        setOverlay("none");
                    } else {
                        setRecentUser(JsonData.results[0].name);
                        requestResetPass(JsonData.results[0].name);
                    }
                })
                .catch((error) => {
                    loginError("Terjadi Kesalahan!");
                });
        }
    }

    async function requestResetPass(name) {
        let uuid = await uuidv4();
        console.log("UUID", name);
        await instance
            .post(uAPIlocal + "/api/v1/requestResetPass", {
                email: tomail.toLowerCase(),
                kode_unik: uuid,
                status: 0,
            })
            .then((response) => {
                console.log("response", response.data);
                nodemailer(uuid, name);
            })
            .catch((error) => {
                loginError("Terjadi Kesalahan!");
            });
    }

    async function nodemailer(key, name) {
        await instance
            .post(uAPIlocal + "/api/v1/mail", {
                username: name,
                tomail: tomail,
                url: 'http://' + window.location.hostname + ':3000' + "/reset-password?token=" + key,
            })
            .then((response) => {
                if (response.data.msg === "success") {
                    setDisplayReset("block");
                    setOverlay("none");
                    handleClose();
                    swalSuccess("Berhasil Dikirim!");
                } else if (response.data.msg === "fail") {
                    alert("Oops, something went wrong. Try again");
                    setDisplayReset("block");
                }
            });
    }

    function onClose_() {
        setLoader(false);
        setLogin(true);
    }

    function toggleShow() {
        setHidden(!hidden);
    }

    function onChange(e) {
        console.log(e.target.value);
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    function onChangeRemember(e) {
        // setRememberMe(e.target.checked);
    }

    const [crp, setCrp] = useState(null);


    function openModal() {
        Swal.fire({
            title: '<strong style={{fontFamily: "Montserrat"}}>Syarat & Ketentuan</u></strong>',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },

            html:
                '<p style={{fontFamily: "Montserrat"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.' +
                'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, ' +
                'when an unknown printer took a galley of type and scrambled it to make a type specimen book. ' +
                'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.,</p>',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Great!',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonText:
                '<i class="fa fa-thumbs-down"></i>',
            cancelButtonAriaLabel: 'Thumbs down'

        })
    }

    useEffect(() => {

        localStorage.setItem("lang", 'en');

     

        // console.log(location);
        // console.log(query);

        // if (CSRFToken === undefined) {
        //     getCSRFToken();
        // }

        // _csrfPart
        // getCSRFToken();

          if (captcha === false) {
           setCaptcha(true);
          }

          if (email_user != "" || password != "") {
           setCaptcha(true);
          }

          if (captcha === false) {
           loadCaptchaEnginge(6, "#CCD9DD", "#000", "numbers");
          }

        if (loginNotif === false && login_alert != null) {
            swalWithBootstrapButtons.fire({
                icon: statusAlert == "logout" ? "success" : "error",
                title: login_alert,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            });
               setCaptcha(true);

            setTimeout(function () {
                setLogin_alert(null)
            }, 1000);
        }

          if (login_alert != null) {
           setLoginNotif(true);
           setCaptcha(true);
          }
    });

    const getCSRFToken = async () => {
        await instance
            .get(uAPIlocal + "/getCSRFToken")
            .then((response) => {
                console.log('csrf', response);
                // setCSRFToken(response.data.CSRFToken);
                // sendToken();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // testing token
    const sendToken = async (csrf) => {
        //  console.log(document.cookie);
        //   console.log(cookies);

        await instance({
            method: "post",
            url: uAPIlocal + "/api/v1/auth_csrf",
        })
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    async function onSubmit(e) {
        e.preventDefault();
        await setLoader(true);
        let user_captcha = document.getElementById("captha").value;
        if (validateCaptcha(user_captcha) == true) {
        loadCaptchaEnginge(6, "#CCD9DD", "#000", "numbers");
        document.getElementById("captha").value = "";
        if (email_user === "" && password === "") {
            await loginError("Email dan password harus diisi!");
        } else {
            await instance
                .post(uAPIlocal + "/api/v1/auth", {
                    email_user: email_user,
                    password: password,
                })
                .then((response) => {
                    console.log("response", response.data)
                    return response.data;
                })
                .then((JsonData) => {
                    // console.log(JsonData);
                    const status = JsonData.status;

                    if (status !== 200) {
                        loginError(JsonData.results);
                    } else {
                        // if (rememberMe) {
                        //     localStorage.rememberMe = rememberMe;
                        //     localStorage.setItem("detail_login", JSON.stringify(detail_login));
                        // }

                        //    setDetail_login({
                        //     d_u: CryptoJS.AES.encrypt(
                        //      JsonData.results[0].id_user.toString(),
                        //      JsonData.results[0].password.slice(0, 6)
                        //     ).toString(),
                        //     kode_perusahaan: JsonData.results[0].kode_perusahaan,
                        //     nama_perusahaan: JsonData.results[0].nama_perusahaan,
                        //     nama_user: JsonData.results[0].nama_user,
                        //     p_: JsonData.results[0].password,
                        //    });

                        // _csrfPart
                        // getLoginKey();

                        setDetail_login(JsonData.results[0])
                        loginSuccess("Login berhasil!");
                        localStorage.status_login = true;
                        localStorage.setItem("Company_ID", JsonData.results[0].Company_ID);
                        localStorage.setItem("nama_pt", JsonData.results[0].name);
                        localStorage.setItem("detail_login", JsonData.results[0]);
                        localStorage.setItem("login", true);
                        localStorage.setItem("id_user", JsonData.results[0].id);
                        localStorage.setItem("name", JsonData.results[0].name);
                        localStorage.setItem("lvl", JsonData.results[0].level);



                        const formData = new FormData();
                        const config = {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        };

                        formData.append('id_user', localStorage.getItem("id_user"));
                        formData.append('name', localStorage.getItem("name"));
                        formData.append('message', "Login");


                        instance.post(uAPIlocal + '/api/v1/log', formData, config)
                            .then(response => {
                                console.log(response)
                                if (response.data.status === 406) {
                                    console.log(response)

                                } else {
                                    console.log(response)
                                }

                            })
                            .catch(function (error) {
                                console.log(error);
                            });






                    }
                })
                .catch((error) => {
                    loginError("Terjadi Kesalahan!");
                });




        }
          } else {
           await loginError("Captha Salah!");
           document.getElementById("captha").value = "";
          }
        
    }

    const getLoginKey = async () => {
        await instance({
            method: "post",
            url: uAPIlocal + "/login",
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    if (login === true && detail_login) {
        // alert("kondisi 1")
        return (
            <Redirect
                to={{
                    pathname: "/admin",
                    state: {
                        detail_login: detail_login,
                    },
                }}
            />
        );
    }
    // else if (Cookies.get("_login") != undefined) {
    //     alert("kondisi 2 Login")
    //     return (
    //         <Redirect
    //             to={{
    //                 pathname: "/admin",
    //                 state: {
    //                     detail_login: detail_login,
    //                 },
    //             }}
    //         />
    //     );
    // }
    else { }

    // alert(Cookies.get("_login"))

    return (
        <>

            {/* reset password */}
            <div id="overlay" style={{ display: overlay }}>
                <div className="w-100 d-flex justify-content-center align-items-center">
                    <div className="sk-chase">
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                dialogClassName="custom-modal"
                keyboard
                centered
            >
                <Modal.Header closeButton style={{ backgroundColor: "#0b6e51" }}>
                    <Modal.Title
                        style={{ fontSize: 18, color: 'white', fontFamily: "Montserrat" }}
                    >
                        Reset Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="App">
                            <div className="form-group mb-3">
                                <div className="text-center d-flex justify-content-between mt-4">
                                    <p style={{ fontFamily: "Montserrat" }}>Masukkan Email</p>
                                </div>
                                <input
                                    className="num-09-input-field-instance-new"
                                    placeholder=""
                                    type="text"
                                    name="new_password"
                                    id="myInput"

                                    onChange={(e) => {
                                        settomail(e.target.value);
                                    }}
                                    autoComplete="off"
                                />
                            </div>

                            <div className="submit-button">
                                {state.loader ? (
                                    <Loader type="Circles" color="#00BFFF" height={40} width={100} />
                                ) : (
                                    <div className="accordion-reset">
                                        <input
                                            type="submit"
                                            onClick={sendmail}
                                            name=""
                                            style={{ display: displayReset }}
                                            value="Kirim"
                                            className="num-03-filled-button-instance-1-new"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: "0px solid #ffffff" }}>
                    <div className="back-button-mini">
                        <input type="button" name="" onClick={handleClose} value="CLOSE" />
                    </div>
                </Modal.Footer>
            </Modal>


            <div className="Auth-form-container">
                <img alt='image1' className="frame-2-new" src={frame2} />
                <form className="Auth-form" onSubmit={onSubmit}>

                    <div className="Auth-form-content">


                        <Row>

                            <div className="col-12 d-flex justify-content-center text-center" style={{ marginBottom: '20px' }}>
                                <img alt='image2' className="logo-new" src={logo} />
                            </div>

                        </Row>

                        {/* <h3 className="Auth-form-title">Sign In</h3> */}
                        <div className="form-group mt-3">
                            <label className="label-new">Email</label>
                            <input name="email_user" type="email" className="num-09-input-field-instance-new" placeholder="Enter email" onChange={onChange} autoFocus autoComplete="off" required />
                        </div>
                        <div className="form-group mt-3 icon-div">
                            <label className="label-new">Password</label>
                            <input type={hidden ? "password" : "text"} className="num-09-input-field-instance-new" placeholder="******" name="password" onChange={onChange} autoComplete="off" required />

                            <a href="#" className="psw_child" onClick={toggleShow}>
                                {hidden === true ? (
                                    <i className="fa fa-eye"></i>
                                ) : (
                                    <i className="fa fa-eye-slash"></i>
                                )}
                            </a>

                        <br/>

                         <input
                          id="captha"
                          name="captha"
                          type="text"
                          placeholder="Enter Captcha"
                          required
                          autofocus
                          className="num-09-input-field-instance-new"
                          onChange={onChange}
                         
                        />
                        <br />
                        <LoadCanvasTemplate />


                        </div>
                        <div className="form-group mt-3 icon-div" style={{ marginBottom: '40px' }}>
                            <a
                                href="#"
                                style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    fontFamily: 'Montserrat',
                                    textDecoration: 'none',
                                    position: 'absolute',
                                    right: 0,
                                    top: '-12px'
                                }}
                                onClick={handleShow}
                            >
                                Lupa Password?
                            </a>
                        </div>
                        <div className="d-grid gap-2 mt-3">

                            {state.loader ? (
                                <Loader type="Circles" color="#00BFFF" height={40} width={100} />
                            ) : (
                                <button type="submit" className="num-03-filled-button-instance-1-new"><p className='fontlogin'>Log In</p></button>
                            )}


                        </div>
                        <p className="text-right mt-2">
                            <a href="#" style={{ fontFamily: 'Montserrat' }} onClick={openModal}>Syarat & Ketentuan</a>
                        </p>
                    </div>
                </form>
                <img alt='image12' className="frame-1-new" src={frame1} />
            </div>

        </>
    );
}
