import React, { Component } from "react";
import './body.scss';
import {
  Container,
  Row,
  Col,
  // Card,
  Button,
  // ButtonGroup,
  // Form,
  // FormControl,
  // Breadcrumb,
  // Carousel,
  // List,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
// import ListGroup from "react-bootstrap/ListGroup";
import InputGroup from "react-bootstrap/InputGroup";
// import Modal from "react-bootstrap/Modal";
// import Collapse from "react-bootstrap/Collapse";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
// import filterFactory, {
//   dateFilter,
//   Comparator,
// } from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
  // CSVExport,
} from "react-bootstrap-table2-toolkit";
// import cellEditFactory from "react-bootstrap-table2-editor";
import Navbar from './Navbar';
import maskGroup from "../../assets/maskGroup.png";

import axios from 'axios';
import { uAPIlocal } from '../lib/config';
import { withRouter, Redirect } from 'react-router-dom';
import ButtonPrint from './ButtonPrint';

let source = axios.CancelToken.source();
axios.defaults.withCredentials = true;

class Report extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.state = {
      datas: [],
    };
  }



  async componentDidMount() {

    // console.log("INI STATE PROPS", this.props.location.state)

    // const formData = new FormData();
    // const config = {
    //   headers: {
    //     'content-type': 'multipart/form-data'
    //   }
    // };

    // formData.append('id_user', localStorage.getItem("id_user"));
    // formData.append('name', localStorage.getItem("name"));
    // formData.append('message', "Akses Peserta");


    // await axios.post(uAPIlocal + '/api/v1/log', formData, config)
    //   .then(response => {
    //     console.log(response)
    //     if (response.data.status === 406) {
    //       console.log(response)

    //     } else {
    //       console.log(response)
    //     }

    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });


  }


  render() {


    return (
      <>

        <ButtonPrint item={{}} />

      </>
    );
  }
}
export default withRouter(Report)
