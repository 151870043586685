import React, { Component, useEffect, useState } from "react";
import './body.scss';
// import {
//   Container,
//   Row,
//   Col,
//   Card,
//   Button,
//   ButtonGroup,
//   Form,
//   FormControl,
//   Breadcrumb,
//   Carousel,
//   List,
// } from "react-bootstrap";
// import ListGroup from "react-bootstrap/ListGroup";
// import InputGroup from "react-bootstrap/InputGroup";
// import Modal from "react-bootstrap/Modal";
// import Collapse from "react-bootstrap/Collapse";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory, {
//   PaginationProvider,
//   PaginationListStandalone,
// } from "react-bootstrap-table2-paginator";
// import filterFactory, {
//   dateFilter,
//   Comparator,
// } from "react-bootstrap-table2-filter";
// import ToolkitProvider, {
//   Search,
//   CSVExport,
// } from "react-bootstrap-table2-toolkit";
// import cellEditFactory from "react-bootstrap-table2-editor";
import Navbar from './Navbar';
// import maskGroup from "../../assets/maskGroup.png";
import Loader from 'react-loader-spinner';

import axios from 'axios';
import { uAPIlocal } from '../lib/config';




export default function Kesimpulan() {

  // const [dataConclusion, setDataConclusion] = useState([]);
  // const [dataConclusionll, setDataConclusionll] = useState([{}, {}, {}]);
  const [page, setPage] = useState(1);
  const [pageLenght, setPageLenght] = useState(0);
  const [detail, setDetail] = useState(false);
  // const [detailSlice, setDetailSlice] = useState([0, 3]);

  const [resultPeningkatan, setrRsultPeningkatan] = useState([]);
  const [resultPenurunan, setResultPenurunan] = useState([]);

  const [loading, setLoading] = useState(true);



  

  useEffect(async () => {

    var id_company = localStorage.getItem("Company_ID");
    // var id_company = '0383';
    await axios.get(uAPIlocal + '/api/v1/kesimpulan', { params: { Company_ID: id_company } })
      .then(function (response) {
        return response.data;
      })
      .then(response => {
        setrRsultPeningkatan(response.results?.resultPeningkatan)
        setResultPenurunan(response.results?.resultPenurunan)


        let dataCount = response?.results?.resultPeningkatan?.length > response?.results?.resultPenurunan?.length ? response?.results?.resultPeningkatan?.length : response?.results?.resultPenurunan?.length

        let size = 3
        let length =
          dataCount % size === 0
            ? dataCount / size
            : parseInt(dataCount / size) + 1;

        setPageLenght(length)
        setLoading(false)
        // setDataConclusion(response.results)
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
      });
  }, [])

  const loadingImage = (
    <div className="text-center pd-90" >
      <Loader type="Bars" color="#0f473e" height={60} width={100} />
      Loading
    </div>
  )

  return (
    <>
      <Navbar />
      <div className="goodkesimpulan ">
        {!detail ? <div className="">
          <div className="d-flex my-4">
            <div className="width-conc">
              <p className="concTitle color-green">Things you are doing exceptionally good op</p>
              {loading ? loadingImage : resultPenurunan?.length ? resultPenurunan?.slice((page * 1 - 1) * 3, page * 3)?.map((res, i) => <KesimpulanList key={i} data={res} />) : <div className="concDesc">Data tidak ditemukan</div>}
            </div>
            <div className="mx-5">
              <svg width="4" height="966" viewBox="0 0 4 966" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="4" height="966" rx="2" fill="#0F473E" />
              </svg>
            </div>
            <div className="display-relative width-conc">
              <p className="concTitle color-red">Areas need to be improved</p>
              {loading ? loadingImage : resultPeningkatan?.length ? resultPeningkatan?.slice((page * 1 - 1) * 3, page * 3)?.map((res, i) => <KesimpulanList isImproveved={true} key={i} data={res} />) : <div className="concDesc">Data tidak ditemukan</div>}
            </div>
          </div>
          <div>
            <KesimpulanPagination1 pageLenght={pageLenght} page={page} setPage={(e) => { setPage(e) }} nextmsg={"What’s Next?"} setDetail={() => { setDetail(!detail); setPage(1) }} />
          </div>
        </div> :
          <DetailKesimpulan1 setDetail={() => { setDetail(!detail); setPage(1) }} pageLenght={pageLenght} page={page} setPage={(e) => { setPage(e); }} page={page} />}
      </div>
    </>
  )
}


function KesimpulanList({
  data,
  isImproveved = false,

}) {
  return (
    <div>
      <u className="concTitle color-black">{data?.titles}</u>
      <div className="d-flex">
        <div className="display-relative p-3">
          <div className="concPersen font-weight-normal color-DarkGreen">
            <div className="">{data?.startPercent ?? 0}%</div>
          </div>
          <div className="display-absolute concPersenYear rounded-circle font-weight-bold">{data?.startYear ?? 0}</div>
        </div>
        <div className="display-relative p-3">
          <div className={"concPersen font-weight-bold " + (isImproveved ? "color-red" : "color-DarkGreen")}>
            {data?.lastPercent ?? 0}%
          </div>
          <div className="display-absolute concPersenYear rounded-circle font-weight-bold">{data?.lastYear ?? 0}</div>
        </div>

      </div>
      <p className="concDesc" >{data?.desc}</p>
    </div>
  )
}


function DetailKesimpulan1({ setDetail, pageLenght, setPage, page }) {
  return (
    <div className="h-screen">
      <div className="w-50">
        <div className="color-green font-weight-bold font-20 my-3">Kondisi kerja yang kondusif berpengaruh besar dalam kondisi kesehatan karyawan. Berikut hal-hal yang perlu diperhatikan untuk mencapai hal tersebut:
        </div>
        <u className="concTitle font-weight-bold color-black ">
          Kondisi kerja:
        </u>
        <p className="concDesc">
          Sirkulasi udara dan pencahayaan matahari yang baik berpengaruh dalam kesehatan dan efisiensi karyawan dalam bekerja. Dalam hal-hal tersebut performa KTB sudah cukup baik. Tetapi untuk beberapa divisi, seperti HRD, perlu ada perbaikan dalam kondisi pencahyaan matahari.
        </p>
        <u className="concTitle font-weight-bold color-black">
          Aktifitas:
        </u>
        <p className="concDesc">
          Olahraga rutin dan aktifitas ringan setiap hari sangat membantu dalam peningkatan kesehatan karyawan secara keseluruhan. Perusahaan dapat membantu membuat berbagai macam program rutin berolahraga bersama. Dan juga dapat membuat program otomatis gerakan senam ringan 5 menit sebelum waktu pulang. Hal ini dapat dimonitor oleh divisi HRD untuk memastikan berjalannya program - program tersebut.
        </p>
        <u className="concTitle font-weight-bold color-black">
          Pola hidup sehat:
        </u>
        <p className="concDesc">
          Berikut adalah 5 pola hidup sehat yang dianjurkan untuk setiap karyawan KTB:<br />
          1. Checkup Reguler<br />
          2. Berolahraga, usahakan olahraga 30 menit setiap hari<br />
          3. Berhenti merokok<br />
          4. Makan makanan yang bergizi<br />
          5. Istirahat yang cukup<br />
        </p>
      </div>
      <KesimpulanPagination1 nextmsg={"Kesimpulan"} setDetail={() => setDetail()} pageLenght={pageLenght} page={page} setPage={(e) => setPage(e)} />
    </div>
  )
}


// function DetailKesimpulan2() {
//   return (
//     <div>
//       <div class="row">
//         <div class="col px-4">
//           <p className="concTitle">Things you are doing exceptionally good op</p>

//           <p>Anemia:</p>

//           Fungsi Lemak:
//           Total kolesterol
//           Triglisedrida
//           LDL
//           di atas normal berapa banyak (kesimpulan)
//           kemajuan atau kemunduran, dari pembanding tahun - tahun sebelumnya

//           HDL:
//           di bawah berapa banyak (buat kesimpulan)
//           kemajuan atau kemunduran, dari pembanding tahun - tahun sebelumnya

//           Fungsi Hati:
//           SGPT
//           SGOT
//           Kesimpulannya
//           kemajuan atau kemunduran, dari pembanding tahun - tahun sebelumnya

//           Fungsi Ginjal:
//           Ureum
//           Kreatinin
//           Asam Urat
//           Kesimpulannya
//           kemajuan atau kemunduran, dari pembanding tahun - tahun sebelumnya

//           Urin:

//           Temuan Paru(Rontgen, apakah bisa dirubah jadi fungsi):

//           Temuan Jantung(EKG, apakah bisa dirubah jadi fungsi:)
// </div>
//         <div class="col px-4">
//         <p className="concTitle color-red">Areas need to be improved</p>
//           </div>
//         </div>
//     </div>
//   )
// } 


function KesimpulanPagination1({
  nextmsg = "",
  pageLenght = 10,
  page = 1,
  setPage,
  setDetail,
  msgTrue = false
}) {
  return (
    <div className="bottom-0 d-flex justify-content-between align-items-center">
      {msgTrue ? <div className="rounded-pill p-conc font-weight-bold mx-3 text-light bg-green" onClick={() => { setDetail() }}>
        <span className="font-weight-bold color-DarkGreen bg-light rounded-circle rounded-pagination-msg mr-2">{"<"}</span>{nextmsg}
      </div> : null}
      <nav className="bg-DarkGreen rounded-pill p-3 max-width">
        <div class="d-flex justify-content-between align-items-center">
          <div onClick={() => page > 1 && setPage(page - 1)}>
            <a className="font-weight-bold color-DarkGreen bg-light rounded-circle rounded-pagination">{"<"}</a>
          </div>
          {page <= pageLenght - page ? (
            [page, page + 1, page + 2, "...", pageLenght].map((res, i) =>
              <div key={i} onClick={() => { res != "..." && setPage(+res) }}>
                <a a className="font-weight-bold text-light mx-4">{`${res}`}</a>
              </div>
            )) : (
            [pageLenght - 4, pageLenght - 3, pageLenght - 2, pageLenght - 1, pageLenght].map((res, i) =>
              <div key={i} onClick={() => { setPage(+res) }}>
                <a a className="font-weight-bold text-light mx-4">{+res}</a>
              </div>
            ))}
          <div onClick={() => { if (page <= pageLenght - 1) { setPage(page + 1) } }}>
            <a className="font-weight-bold color-DarkGreen bg-light rounded-circle rounded-pagination">{">"}</a>
          </div>
        </div>
      </nav>
    </div>
  )
}

