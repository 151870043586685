import React, { Component } from 'react';
import '../body.scss';
import { Tabs, TabPanel } from 'rc-easyui';
import Patient from './Patient';
import Dept from './Dept';
import All from './All';
import JumlahTotal from './JumlahTotal';
import Export from './Export';

class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadPATIENT: true,
            loadDEPT: false,
            loadALL: false,
            loadEXPORT: false,
            loadJUMLAH: false,
        }
        this.onTabSelect = this.onTabSelect.bind(this);
        this.onTabClose = this.onTabClose.bind(this);
        this.onExpand = this.onExpand.bind(this);
        this.onCollapse = this.onCollapse.bind(this);
    }
    onTabSelect(event) {
        // console.log(event.props.title)
        if (event.props.title === 'PATIENT') {
            this.setState({
                loadPATIENT: true,
                loadDEPT: false,
                loadALL: false,
                loadEXPORT: false,
                loadJUMLAH: false,
            })
        } else if (event.props.title === 'DEPT') {
            this.setState({
                loadPATIENT: false,
                loadDEPT: true,
                loadALL: false,
                loadEXPORT: false,
                loadJUMLAH: false,
            })
        } else if (event.props.title === 'ALL') {
            this.setState({
                loadPATIENT: false,
                loadDEPT: false,
                loadALL: true,
                loadEXPORT: false,
                loadJUMLAH: false,
            })
        } else if (event.props.title === 'EXPORT') {
            this.setState({
                loadPATIENT: false,
                loadDEPT: false,
                loadALL: false,
                loadEXPORT: true,
                loadJUMLAH: false,
            })
        } else if (event.props.title === 'JUMLAH TOTAL NORMAL DAN ABNORMAL') {
            this.setState({
                loadPATIENT: false,
                loadDEPT: false,
                loadALL: false,
                loadEXPORT: false,
                loadJUMLAH: true,
            })
        }

    }
    onTabClose(event) {
        console.log(event)
    }
    onExpand() {
        console.log('onExpand')
    }
    onCollapse() {
        console.log('onCollapse')
    }
    render() {
        return (
            <Tabs onTabSelect={this.onTabSelect} >
                <TabPanel title="PATIENT" >
                    {
                        this.state.loadPATIENT ? (
                            <Patient />
                        ) : null
                    }
                </TabPanel>
                <TabPanel title="DEPT">
                    {
                        this.state.loadDEPT ? (
                            <Dept />
                        ) : null
                    }
                </TabPanel>
                <TabPanel title="ALL">
                    {
                        this.state.loadALL ? (
                            <All />
                        ) : null
                    }
                </TabPanel>
                <TabPanel title="EXPORT">
                    {
                        this.state.loadEXPORT ? (
                            <Export />
                        ) : null
                    }
                </TabPanel>
                <TabPanel title="JUMLAH TOTAL NORMAL DAN ABNORMAL">
                    {
                        this.state.loadJUMLAH ? (
                            <JumlahTotal />
                        ) : null
                    }
                </TabPanel>
            </Tabs>
        )
    }
}
export default Company;