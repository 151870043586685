import React, { Component } from 'react';
import {
  Link,
  // Switch,
  // Route,
  Redirect,
  Route,
  Switch,
  // Link,
  withRouter
} from "react-router-dom";
import './header.scss';
import Dashboard from './page-content/Dashboard';
import PostType from './PostType';
import logoBioMedika from "../assets/logoBioMedika.svg";
import dashboardIcon from "../assets/new/dashboard.png";
import dashboardIconActive from "../assets/new/dashboardactive.png";
import garisbawahIconActive from "../assets/new/garisbawahactive.png";
import top10Icon from "../assets/new/top10.png";
import deptIcon from "../assets/new/icondept.png";
import kesimpulanIcon from "../assets/new/kesimpulan.png";
import people from "../assets/people_icon.png";
import admin from "../assets/admin.png";
import report from "../assets/report.png";
import chevronRight from "../assets/font-awesome/black/png/32/chevron-right.png";
import "./assets/sidebar.css";

import "./assets/sidebarResponsive.css";
import back from "../assets/back.svg";
import $ from "jquery";
import Header from "./page-content/Navbar";
import Footer from "./Footer";
import Dropdown from "react-bootstrap/Dropdown";
import Cookies from 'js-cookie';
import { withTranslation } from 'react-i18next';

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: localStorage.getItem("login"),
      detail_login: localStorage.getItem("detail_login"),
      path: this.props.history.location.pathname,
      menuLogout: false,
      openNavigation: false,
    }
    this.LogoutClick = this.LogoutClick.bind(this);
    this.HideLogout = this.HideLogout.bind(this);
    this.Logout = this.Logout.bind(this);
    this.active = this.active.bind(this);
    this.openNav = this.openNav.bind(this);
  }
  LogoutClick() {
    this.setState({
      menuLogout: !this.state.menuLogout,
    })
  }
  HideLogout() {
    this.setState({
      menuLogout: false,
    });
  }
  Logout() {
    this.setState({
      login: false,
    })
  }
  myalert() {
    alert("testing");
  }
  active() {

    if (window.innerWidth <= 767) {
      this.setState({
        openNavigation: !this.state.openNavigation,
      })
    }

    this.setState((state, props) => {
      return {
        path: props.location.pathname,
      };
    }, () => console.log(this.state.path))
  }
  openNav() {
    this.setState({
      openNavigation: !this.state.openNavigation,
    })

  }


  componentDidMount() {
    this.props.i18n.changeLanguage(localStorage.getItem("lang"));
    const match = this.props.match.path;
    console.log(this.state.path === match)
    console.log("company_id", localStorage.getItem("Company_ID"));
    console.log("nama_pt", localStorage.getItem("nama_pt"));
    console.log("login", localStorage.getItem("login"));
    console.log("login", localStorage.getItem("login"));



  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.history.location.pathname !== this.state.path) {
      this.setState((state, props) => {
        return {
          path: props.location.pathname,
        };
      }, () => console.log(this.state.path))
    }
  }

  render() {
    // if (Cookies.get("_login") == undefined) {
    // return (<Redirect to="/" />)
    // alert('null')
    // }
    const match = this.props.match.path;

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      // <li
      //   className={"nav-item " + (this.state.path === "/admin/pendaftaran" ? "active" : "")}
      // >
      //   <a
      //     ref={ref}
      //     onClick={(e) => {
      //       e.preventDefault();
      //       onClick(e);
      //     }}
      //     className="nav-link"
      //     type="button"
      //   >
      //     <span style={{color:'#fff'}}>
      //       <i className="fa fa-id-card-o"></i> {children} &#x25bc;
      //     </span>
      //   </a>
      // </li>
      <li className={"nav-item sidebar-list"}>
        <a
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          className="nav-link"
          type="button"
        >
          <div className="sidebar-nav-div">
            {<img alt='image2' className="people" src={(admin)} style={{ marginTop: '4px' }} />}
            <span className="peopletext" style={{ color: (this.state.path === '/admin' ? '#C4E9CD' : 'white'), fontSize: '12px' }}>{children} &#x25bc;</span>
          </div>
        </a>
      </li>

    ));

    return (
      <>

        <div
          style={{
            // backgroundColor: this.state.isFullScreen ? "white" : null,
            // height: "800px",

            height: 'auto'
          }}
        >


          <div id="wrapper" className={!this.state.openNavigation ? "toggled" : null}>

            <div
              id="sidebar-wrapper"
              className="vertical-nav"
            >
              <img alt='image1' className="logo-bio-medika-new" src={logoBioMedika} />
              <ul className="nav flex-column mb-0" style={{ marginTop: "40px" }}>
                <li className={"nav-item sidebar-list " + (this.state.path === "/admin" ? "active" : "")}>
                  <Link to={`${this.props.match.path}`} onClick={this.active} className="nav-link" type="button">
                    <div className="sidebar-nav-div">
                      {<img alt='image2' className="people" src={(this.state.path === '/admin' ? dashboardIconActive : dashboardIcon)} style={{ filter: (this.state.path === '/admin' ? 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(99%) contrast(26%)' : ''), marginTop: '4px' }} />}
                      <span className="peopletext" style={{ color: (this.state.path === '/admin' ? '#C4E9CD' : 'white'), fontSize: '12px' }}>{this.props.t('dasbor')}</span>
                    </div>
                  </Link>
                  {(this.state.path === '/admin' ? <img alt='image3' className="garisbawahactive" src={garisbawahIconActive} /> : null)}
                </li>


                <li className={"nav-item sidebar-list " + (this.state.path === "/admin/top10" ? "active" : "")}>
                  <Link to={`${this.props.match.path}/top10`} onClick={this.active} className="nav-link" type="button">
                    <div className="sidebar-nav-div">
                      {<img alt='image4' className="people" src={top10Icon} style={{ filter: (this.state.path === '/admin/top10' ? 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(99%) contrast(26%)' : ''), marginTop: '4px' }} />}
                      <span className="peopletext" style={{ color: (this.state.path === '/admin/top10' ? '#C4E9CD' : 'white'), fontSize: '12px' }}>{this.props.t('top10')}</span>
                    </div>

                  </Link>
                  {(this.state.path === '/admin/top10' ? <img alt='image5' className="garisbawahactive" src={garisbawahIconActive} /> : null)}
                </li>


                <li className={"nav-item sidebar-list " + (this.state.path === "/admin/departemen" ? "active" : "")}>
                  <Link to={`${this.props.match.path}/departemen`} onClick={this.active} className="nav-link" type="button">
                    <div className="sidebar-nav-div">
                      {<img alt='image6' className="people" src={deptIcon} style={{ filter: (this.state.path === '/admin/departemen' ? 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(99%) contrast(26%)' : ''), marginTop: '4px' }} />}
                      <span className="peopletext" style={{ color: (this.state.path === '/admin/departemen' ? '#C4E9CD' : 'white'), fontSize: '12px' }}>{this.props.t('departemen')}</span>
                    </div>

                  </Link>
                  {(this.state.path === '/admin/departemen' ? <img alt='image7' className="garisbawahactive" src={garisbawahIconActive} /> : null)}
                </li>

                <li className={"nav-item sidebar-list " + (this.state.path === "/admin/people" ? "active" : "")}>
                  <Link to={`${this.props.match.path}/people`} onClick={this.active} className="nav-link" type="button">
                    <div className="sidebar-nav-div">
                      {<img alt='image8' className="people" src={people} style={{ filter: (this.state.path === '/admin/people' ? 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(99%) contrast(26%)' : ''), marginTop: '4px' }} />}
                      <span className="peopletext" style={{ color: (this.state.path === '/admin/people' ? '#C4E9CD' : 'white'), fontSize: '12px' }}>{this.props.t('peserta')}</span>
                    </div>

                  </Link>
                  {(this.state.path === '/admin/people' ? <img alt='image9' className="garisbawahactive" src={garisbawahIconActive} /> : null)}
                </li>

                {/* <li className={"nav-item sidebar-list " + (this.state.path === "/admin/report" ? "active" : "")}>
                  <Link to={`${this.props.match.path}/report`} onClick={this.active} className="nav-link" type="button">
                    <div className="sidebar-nav-div">
                      {<img alt='image8' className="people" src={report} style={{ filter: (this.state.path === '/admin/report' ? 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(99%) contrast(26%)' : ''), marginTop: '4px' }} />}
                      <span className="peopletext" style={{ color: (this.state.path === '/admin/report' ? '#C4E9CD' : 'white'), fontSize: '12px' }}>{this.props.t('laporan')}</span>
                    </div>

                  </Link>
                  {(this.state.path === '/admin/report' ? <img alt='image9' className="garisbawahactive" src={garisbawahIconActive} /> : null)}
                </li> */}



                <Dropdown style={{ display: localStorage.getItem("lvl") == 0 ? null : 'none' }}>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >Master
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="min-width" align={"end"}>
                    <Dropdown.Item
                      eventKey="1"
                      as={Link}
                      // to={`${this.props.match.path}/itreports`}
                      to={`/admin/master-patient`}
                      onClick={this.active}
                    >
                      <span>
                        <img src={chevronRight} width="14" /> Pasien
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      as={Link}
                      // to={`${this.props.match.path}/techreports`}
                      to={`/admin/master-conc`}
                      onClick={this.active}
                    >
                      <span>
                        <img src={chevronRight} width="14" /> Konklusi
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>


                {/* <li className={"nav-item sidebar-list " + (this.state.path === "/admin/kesimpulan" ? "active" : "")}>
                  <Link to={`${this.props.match.path}/kesimpulan`} onClick={this.active} className="nav-link" type="button">
                    <div>
                      {<img alt='image10' className="people" src={kesimpulanIcon} style={{ filter: (this.state.path === '/admin/kesimpulan' ? 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(99%) contrast(26%)' : ''), marginTop: '4px' }} />}
                      <span className="peopletext" style={{ color: (this.state.path === '/admin/kesimpulan' ? '#C4E9CD' : 'white'), fontSize: '12px' }}>Kesimpulan</span>
                    </div>

                  </Link>
                  {(this.state.path === '/admin/kesimpulan' ? <img alt='image11' className="garisbawahactive" src={garisbawahIconActive} /> : null)}
                </li> */}


              </ul>
              {/* <div id="sidebarCollapse" type="button" className="rotate">
                <img src={back} />
              </div> */}
            </div>

            <div id="page-content-wrapper">
              <div className="container-fluid px-0" style={{ marginTop: "-30px" }}>
                <div
                  id="sidebarCollapse"
                  type="button"
                  className={this.state.openNavigation ? "rotate" : null}
                  onClick={this.openNav}
                >
                  <img src={back} />
                </div>
                {/* <a href="#menu-toggle" onClick={this.openNav} className="btn btn-default" id="menu-toggle">Toggle Menu</a> */}
                <Header />

                <Switch>
                  <Route path={`${match}/:postType`}>
                    <PostType />
                  </Route>
                  <Route path={match}>
                    <Dashboard />
                  </Route>
                </Switch>

              </div>
            </div>
          </div>

          <Footer />
        </div>
      </>
    )
  }
}
// export default withRouter(Index);
export default withRouter(withTranslation()(Index));