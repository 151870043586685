import { useMediaQuery } from 'react-responsive'
import Index from './Login';
// import "./assets/screen.scss";


const Desktop1023 = ({ children }) => {
  const isDesktop = useMediaQuery({ maxWidth: 1023 })
  return isDesktop ? children : null
}

const Desktop1024 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1279.98 })
  return isDesktop ? children : null
}

const Desktop1280 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1280, maxWidth: 1359.98 })
  return isDesktop ? children : null
}

const Desktop1360 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1360, maxWidth: 1439.98 })
  return isDesktop ? children : null
}

const Desktop1440 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1440, maxWidth: 1599.98 })
  return isDesktop ? children : null
}


const Desktop1600 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1600, maxWidth: 1679.90 })
  return isDesktop ? children : null
}

const Desktop1680 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1680, maxWidth: 1919.90 })
  return isDesktop ? children : null
}

const Desktop1920 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1920, maxWidth: 2265.98 })
  return isDesktop ? children : null
}

const Desktop2266 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 2266, maxWidth: 2303.98 })
  return isDesktop ? children : null
}

const Desktop2304 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 2304, maxWidth: 2387.98 })
  return isDesktop ? children : null
}

const Desktop2388 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 2388, maxWidth: 2559.98 })
  return isDesktop ? children : null
}

const Desktop2560 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 2560, maxWidth: 2879.90 })
  return isDesktop ? children : null
}

const Desktop2880 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 2880, maxWidth: 3023.90 })
  return isDesktop ? children : null
}

const Desktop3024 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 3024, maxWidth: 3071.90 })
  return isDesktop ? children : null
}

const Desktop3072 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 3072, maxWidth: 3455.90 })
  return isDesktop ? children : null
}

const Desktop3456 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 3456, maxWidth: 3839.90 })
  return isDesktop ? children : null
}

const Desktop3840 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 3840, maxWidth: 4095.90  })
  return isDesktop ? children : null
}

const Desktop4096 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 4096, maxWidth: 4479.90  })
  return isDesktop ? children : null
}

const Desktop4480 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 4480, maxWidth: 5119.90  })
  return isDesktop ? children : null
}

const Desktop5120 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 5120, maxWidth: 6015.90  })
  return isDesktop ? children : null
}

const Desktop6016 = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 6016, maxWidth: 7200.90  })
  return isDesktop ? children : null
}


const Screen = () => (
  <div>
    {/* <Desktop1023>
      <div className="Logindesktop1023">
        <Index />
      </div>
    </Desktop1023>
    <Desktop1024>
      <div className="Logindesktop1024">
        <Index />
      </div>
    </Desktop1024>
    <Desktop1280>
      <div className="Logindesktop1280">
        <Index />
      </div>
    </Desktop1280>
    <Desktop1360>
      <div className="Logindesktop1360">
        <Index />
      </div>
    </Desktop1360>
    <Desktop1440>
      <div className="Logindesktop1440">
        <Index />
      </div>
    </Desktop1440>
    <Desktop1600>
      <div className="Logindesktop1600">
        <Index />
      </div>
    </Desktop1600>
    <Desktop1680>
      <div className="Logindesktop1680">
        <Index />
      </div>
    </Desktop1680>
    <Desktop1920>
      <div className="Logindesktop1920">
        <Index />
      </div>
    </Desktop1920>
   <Desktop2266>
      <div className="Logindesktop2266">
        <Index />
      </div>
    </Desktop2266>
    <Desktop2304>
      <div className="Logindesktop2304">
        <Index />
      </div>
    </Desktop2304>
    <Desktop2388>
      <div className="Logindesktop2388">
        <Index />
      </div>
    </Desktop2388>
    <Desktop2560>
      <div className="Logindesktop2560">
        <Index />
      </div>
    </Desktop2560>
    <Desktop2880>
      <div className="Logindesktop2880">
        <Index />
      </div>
    </Desktop2880>
    <Desktop3024>
      <div className="Logindesktop3024">
        <Index />
      </div>
    </Desktop3024>
    <Desktop3072>
      <div className="Logindesktop3072">
        <Index />
      </div>
    </Desktop3072>
    <Desktop3456>
      <div className="Logindesktop3456">
        <Index />
      </div>
    </Desktop3456>
    <Desktop3840>
      <div className="Logindesktop3840">
        <Index />
      </div>
    </Desktop3840>
    <Desktop4096>
      <div className="Logindesktop4096">
        <Index />
      </div>
    </Desktop4096>
    <Desktop4480>
      <div className="Logindesktop4480">
        <Index />
      </div>
    </Desktop4480>
    <Desktop5120>
      <div className="Logindesktop5120">
        <Index />
      </div>
    </Desktop5120>
    <Desktop6016>
      <div className="Logindesktop6016">
        <Index />
      </div>
    </Desktop6016>  */}

    <Index /> 
  </div>
)

export default Screen;