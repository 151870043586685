import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  id: {
    translation: {
      "dasbor" : "Dasbor",
      "top10" : "Top 10",
      "peserta" : "Peserta",
      "laporan" : "Laporan",
      "demografi": "Demografi",
      "jenisKelamin": "Jenis Kelamin",
      "usia": "Usia",
      "umur": "Umur",
      "departemen": "Departemen",
      "lakiLaki": "Laki - Laki",
      "perempuan": "Perempuan",
      "totalPeserta": "Total Peserta",
      "normal/temuanAbnormal" : "Normal / Temuan Abnormal",
      "top10temuan" : "Top 10 Temuan",
      "top10peserta" : "Top 10 Peserta",
      "top10departemen" : "Top 10 Departemen",
      "temuan" : "Temuan",
      "orang" : "Orang",
      "dengantemuanterbanyak": "Dengan Temuan Terbanyak",
      "nama" : "Nama", 
      "pesertaDepartemen" : "Peserta Departemen",
      "temuanDepartemen" : "Temuan Departemen",
      "pemeriksaan" : "Pemeriksaan",
      "status" : "Status",
      "pilih" : "Pilih",
      "namaLengkap" : "Nama Lengkap",
      "tanggalLahir" : "Tanggal Lahir",
      "nilaiReferensi" : "Nilai Referensi",
      "satuan" : "Satuan",
      "nilaiNormal": "Nilai Normal",
      "nilaiTest" : "Nilai Test",
      "cari" : "Pencarian"

    }
  },
  en: {
    translation: {
      "dasbor" : "Dashboard",
      "top10" : "Top 10",
      "peserta" : "Participants",
      "laporan" : "Report",
      "demografi": "Demography",
      "jenisKelamin": "Gender",
      "usia": "Age",
      "umur": "Age",
      "departemen": "Department",
      "lakiLaki": "Male",
      "perempuan": "Female",
      "totalPeserta": "Number of participants",
      "normal/temuanAbnormal" : "Normal / Abnormal",
      "top10temuan" : "Top 10 Finds",
      "top10peserta" : "Top 10 Participants",
      "top10departemen" : "Top 10 Department",
      "temuan" : "Findings",
      "orang" : "People",
      "dengantemuanterbanyak": "With the most findings",
      "nama" : "Name", 
      "pesertaDepartemen" : "Department Participants",
      "temuanDepartemen" : "Department Findings",
      "pemeriksaan" : "Test Name",
      "status" : "Status",
      "pilih" : "Choose",
      "namaLengkap" : "Full Name",
      "tanggalLahir" : "DOB",
      "nilaiReferensi" : "Reference Value",
      "satuan" : "Unit",
      "nilaiNormal" : "Normal Value",
      "nilaiTest" : "Test Value",
      "cari" : "Search"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;