import React, { Component } from 'react';
import './Rounded';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom';

// const listItemStyle = {
//   color: "#333",
//   listStyle: "none",
//   textAlign: "left",
//   display: "flex",
//   flexDirection: "row",
//   margin: "8px",
//   fontSize: "12px"
// };

class Barchart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      data: this.props.data,
      options: this.props.options,
      legend: [],
      id: this.props.id,
      year: this.props.year,
      isSmallScreen: false,
      maxChar:0,
      maxSubs:0,
    }
    this.resizeScreen = this.resizeScreen.bind(this);
  }
  componentDidMount() {
    const component = this.bar;
    this.setState({ legend: component.chartInstance.legend.legendItems });
    window.addEventListener("resize", this.resizeScreen());
  }

    resizeScreen() {
        if (window.innerWidth <= 1200) {
            this.setState({ isSmallScreen: false, maxChar:12, maxSubs:10 });;
        } else {
            this.setState({ isSmallScreen: true, maxChar:23, maxSubs:22 });
        }

    }

  redirectPeserta(id, name) {
    this.setState({ redirect: true, idSelected: id, nameSelected: name });
  }

  chartHover(index) {

    var chart = this.bar.chartInstance;
    var segment = chart.getDatasetMeta(0).data[index];
    chart.tooltip._active = [segment];
    chart.tooltip.update();
    chart.updateHoverStyle([segment], null, true)
    chart.render();

  }


  chartLeave(index) {
    var chart = this.bar.chartInstance;
    var segment = chart.getDatasetMeta(0).data[index];
    chart.tooltip._active = [];
    chart.tooltip.update();
    chart.updateHoverStyle([segment], null, false)
    chart.render();

  }

  render() {


    if (this.state.redirect === true) {
      return (<Redirect to={{
        pathname: "/admin/people",
        state: {
          conc_id: this.state.idSelected,
          conc_name: this.state.nameSelected,
          year_selected: this.state.year
        }
      }} />)
    }

    const { legend } = this.state;
    return (
      <div>
        {
          this.state.type ? (
            this.state.type === 'bar' ? (

              <Row style={{ marginTop: '10px' }}>
                <Col xl={6} md={12} className='barChart2'>
                  <Bar
                    ref={ref => (this.bar = ref)}
                    data={this.state.data}
                    options={this.state.options}

                  />
                </Col>

                <Col xl={3} md={6} sm={6} xs={6}>
                  <ul className="mt-8" style={{ marginTop: '10px' }}>
                    {legend.length &&
                      legend.map((item, index) => {
                        let _text = item.text.split(":");
                        if (index < 5) {
                          return (
                            <li className="listFlex_poin_bar2">
                              <table>
                                <td>
                                  <div
                                    style={{
                                      float: 'left',
                                      margin: "6px",
                                      width: "18px",
                                      height: "18px",
                                      backgroundColor: item.fillStyle,
                                      borderRadius: '50%',
                                    }}
                                  />
                                </td>
                                <td className="listFlexSub2" style={{ padding: '0px' }}>
                                  <div className="barChartLegend">
                                    <a style={{ textDecoration: 'none', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} href="#" onMouseOver={(e) => this.chartHover(index)} onMouseLeave={(e) => this.chartLeave(index)} onClick={(e) => this.redirectPeserta(this.state.id[index],_text[0])}>
                                      {/* <p className="redirectText" style={{ fontSize: '12px', textAlign: 'left', marginBottom: '10px', fontFamily: "Montserrat" }}>{ _text[0].split(' ').length > 2 && _text[0].length > this.state.maxChar ? _text[0].substring(0, this.state.maxSubs) + '...' : _text[0] }(<span style={{ fontWeight: 'bold' }}>{_text.localStorage(-1)}</span>)</p> */}
                                      <p className="redirectText" >{_text[0].replace("- ", "").substring(0,24)}(<span style={{ fontWeight: 'bold' }}>{_text.slice(-1)}</span>)</p>
                                    </a>
                                  </div>
                                </td>
                              </table>
                            </li>

                          );
                        }
                      })}

                  </ul>
                </Col>

                <Col xl={3} md={6} sm={6} xs={6}>
                  <ul className="mt-8" style={{ marginTop: '10px' }}>
                    {legend.length &&
                      legend.map((item, index) => {
                        let _text = item.text.split(":");
                        if (index > 4) {
                          return (
                            <li className="listFlex_poin_bar2">
                              <table>
                                <td>
                                  <div
                                    style={{
                                      float: 'left',
                                      margin: "6px",
                                      width: "18px",
                                      height: "18px",
                                      backgroundColor: item.fillStyle,
                                      borderRadius: '50%',
                                    }}
                                  />
                                </td>
                                <td className="listFlexSub2" style={{ padding: '0px' }}>
                                  <div className="barChartLegend">
                                    <a style={{ textDecoration: 'none', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} href="#" onMouseOver={(e) => this.chartHover(index)} onMouseLeave={(e) => this.chartLeave(index)} onClick={(e) => this.redirectPeserta(this.state.id[index],_text[0])}>
                                      {/* <p className="redirectText" style={{ fontSize: '12px', textAlign: 'left', marginBottom: '10px', fontFamily: "Montserrat" }}>{ _text[0].split(' ').length > 2 && _text[0].length > this.state.maxChar ? _text[0].substring(0, this.state.maxSubs) + '...' : _text[0] }(<span style={{ fontWeight: 'bold' }}>{_text.slice(-1)}</span>)</p> */}
                                      <p className="redirectText" >{_text[0].replace("- ", "").substring(0,24)}(<span style={{ fontWeight: 'bold' }}>{_text.slice(-1)}</span>)</p>
                                    </a>
                                  </div>
                                </td>
                              </table>
                            </li>

                          );
                        }
                      })}


                  </ul>
                </Col>


                {/* <Col md={12}>
                 <div className="d-flex justify-content-center" style={{marginTop: '-22px', backgroundColor: 'white', borderRadius: '20px', textAlign: "justify", paddingBottom: '0px', paddingTop:'10px' }}>
                  <Row>

                   <Col md={12}>
                  <p style={{fontSize:'12px', fontFamily:"Montserrat", marginBottom:'0px' }}><b>Notes:</b></p>
                  </Col>
                   <Col md={12}>
                   <p style={{fontSize:'12px', fontFamily:"Montserrat" }}>Top 10 Departemen: Departemen dengan temuan departemen sesuai dengan pemeriksaan.</p>
                  </Col>

                  </Row>
                 </div>
                </Col>  */}


              </Row>


            ) : this.state.type === 'horizontalBar' ? (
              <HorizontalBar
                ref={this.canvasRef}
                data={this.state.data}
                // width={100}
                // height={100}
                options={this.state.options}
              />
            ) : null
          ) : null
        }

      </div >

      // <canvas ref={this.canvasRef} />
    )
  }
}
export default Barchart;