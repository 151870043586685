import React, { Component } from 'react';
import './body.scss';
import {
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import './dashboard.css';
import axios from 'axios';
import Barchart from '../chart/BarChart2';
import PieChart from '../chart/PieChartPrint';
import DoughnutChart2 from '../chart/DoughnutChart2Print';
import { uAPIlocal } from '../lib/config'
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'react-calendar/dist/Calendar.css';
import dropdownIcon from "../../assets/br_down.png";
import GrowthArrow from "../../assets/Group 9.png";
import Navbar from './Navbar';
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Navigation } from "swiper";
import "./assets/style.css"
let source = axios.CancelToken.source();
axios.defaults.withCredentials = true;

export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      _loading1: true,
      _loading2: true,
      _loading3: true,
      selectYear: [],
      loading2: true,
      loading3: true,
      loading4: true,
      _yAll: "",
      _y: moment().year(),
      _y2: moment().year(),
      _y3: moment().year(),
      _y4: moment().year(),
      _get10Temuan: [],
      _get10TemuanJumlahOrang: [],
      top10TemuanID: [],
      totalPeserta: 0,
      isSmallScreen: false,
    }
    this.getGrafikDash = this.getGrafikDash.bind(this);
    this.getDemografiGender = this.getDemografiGender.bind(this);
    this.getDemografiUmur = this.getDemografiUmur.bind(this);
    this.getDemografiDepartment = this.getDemografiDepartment.bind(this);
    this.getNormalAbnormal = this.getNormalAbnormal.bind(this);
    this.get10Temuan1 = this.get10Temuan1.bind(this);
    this.getSelectYear = this.getSelectYear.bind(this);
    source = axios.CancelToken.source();
    this.resizeScreen = this.resizeScreen.bind(this);

  }

  async componentDidMount() {

    console.log("ok tahun", this.state.selectYear);
    this.getSelectYear();
    setInterval(() => {
      console.log('Interval triggered');
    }, 3000);

    // localStorage.setItem("path", "DASHBOARD");
    window.addEventListener("resize", this.resizeScreen());
    const formData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      withCredentials: true
    };

    formData.append('id_user', localStorage.getItem("id_user"));
    formData.append('name', localStorage.getItem("name"));
    formData.append('message', "Akses Dashboard");


    await axios.post(uAPIlocal + '/api/v1/log', formData, config)
      .then(response => {
        console.log(response)
        if (response.data.status === 406) {
          console.log(response)

        } else {
          console.log(response)
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentWillUnmount() {
    if (source) {
      source.cancel("Landing Component got unmounted");
    }
  }

  resizeScreen() {
    // alert(window.innerHeight)
    if (window.innerWidth <= 1200) {
      this.setState({ isSmallScreen: true });
    } else { }

  }

  async getSelectYear() {
    var id_company = localStorage.getItem("Company_ID");

    await axios.get(uAPIlocal + '/api/v1/getselectyear', { params: { Company_ID: id_company }, cancelToken: source.token })
      .then(function (response) {
        return response.data;
      })
      .then(response => {
        const res = response.results;
        console.log("year", res);

        if (res.length === 0) {
          this.getSelectYear();
        } else {
          res.map((item, index) => {
            this.setState({ selectYear: [...this.state.selectYear, item.mcu_year] })
          })

          console.log("yearX", this.state.selectYear);
          this.getDemografiUmur(this.state.selectYear[0]);
          this.getDemografiGender(this.state.selectYear[0]);
          this.getDemografiDepartment(this.state.selectYear[0]);
          this.getNormalAbnormal(this.state.selectYear[0]);
          this.getGrafikDash(this.state.selectYear[0]);

          this.setState({
            _yAll: this.state.selectYear[0],
            _loading1: true,
            _loading2: true,
            _loading3: true,
            loading2: true,
            loading3: true,
            loading4: true,
          })

        }




      })
      .catch(function (error) {
        console.log(error);
      });

    // console.log("yearX", this.state.selectYear);

    // this.getDemografiGender(this.state.selectYear[0]);
    // this.getDemografiUmur(this.state.selectYear[0]);
    // this.getDemografiDepartment(this.state.selectYear[0]);
    // this.getNormalAbnormal(this.state.selectYear[0]);
    // this.getGrafikDash(this.state.selectYear[0]);
    // this.get10Temuan1(this.state.selectYear[0])
    // this.setState({
    //     _yAll: this.state.selectYear[0],
    //     _loading1: true,
    //     _loading2: true,
    //     _loading3: true,
    //     loading2: true,
    //     loading3: true,
    //     loading4: true,
    // })


  }


  async get10Temuan1(year) {
    this.setState({ _get10Temuan: [], _get10TemuanJumlahOrang: [] })
    var id_company = localStorage.getItem("Company_ID");
    let _y = year;
    if (_y === null || _y === undefined) {
      _y = moment().year();
    }
    await axios.get(uAPIlocal + '/api/v1/get10temuan1', { params: { Company_ID: id_company, _y: _y }, cancelToken: source.token })
      .then(function (response) {
        return response.data;
        // console.log("swiper", response.data);
      })
      .then(response => {
        const res = response.results;
        console.log("swiper1", res);
        this.setState({ _get10Temuan: res, loading4: false }, () =>
          this.get10Temuan2(this.state._yAll)
        );

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  async get10Temuan2(year) {
    var id_company = localStorage.getItem("Company_ID");
    let _y = year;
    if (_y === null || _y === undefined) {
      _y = moment().year();
    }


    this.state._get10Temuan.map((item, index) => {



      axios.get(uAPIlocal + '/api/v1/get10temuan2', { params: { Company_ID: id_company, _y: _y, _c: item.conc }, cancelToken: source.token })
        .then(function (response) {
          return response.data;
          // console.log("swiper", response.data);
        })
        .then(response => {
          const res = response.results;
          console.log("res.jumlah_orang", res[0]);

          var percentage = ((res[0].jumlah_orang - res[0].jumlah_orang_prev) / res[0].jumlah_orang) * 100;
          // console.log("percentage", percentage);

          if (isNaN(percentage) == true) {
            percentage = 0;
          }

          var percentageText = "";
          if (percentage > 0) {
            percentageText = "+" + percentage.toFixed(1) + " %"
          } else {
            percentageText = percentage.toFixed(1) + " %"
          }

          // console.log("percentage", percentageText);
          this.setState({ _get10TemuanJumlahOrang: [...this.state._get10TemuanJumlahOrang, { "Conc_ID": item.Conc_ID, "conc": item.conc, "Patient_Age_Range": item.Patient_Age_Range, "jumlah_orang": res[0].jumlah_orang, "status": res[0].status, "percentage": percentageText, "total_orang_laki": res[0].total_orang_laki, "total_orang_perempuan": res[0].total_orang_perempuan }] })
        })
        .catch(function (error) {
          console.log(error);
        });

      // alert(item.Conc_ID);

    })



  }

  async getDemografiGender(year) {
    var id_company = localStorage.getItem("Company_ID");
    let _y = year;
    if (_y === null || _y === undefined) {
      _y = moment().year();
    }
    await axios.get(uAPIlocal + '/api/v1/demografigender', { params: { Company_ID: id_company, _y: _y }, cancelToken: source.token })
      .then(function (response) {
        return response.data;
        // console.log("grafik_g", response.data);
      })
      .then(response => {


        const grafik = response.grafik;


        let resultTotalPeserta = grafik.jumlah.reduce((a, b) => {
          return a + b;
        });



        console.log("grafik_g", grafik);
        this.setState({
          totalPeserta: resultTotalPeserta,
          grafik: {
            // labels: grafik.kesimpulan,
            labels: ['Laki - Laki', 'Perempuan'],
            datasets: [
              {
                label: 'Jumlah',
                data: grafik.jumlah,
                // hoverBackgroundColor: "rgba(255,99,132,0.4)",
                hoverBorderColor: "rgba(0,192,185,255)",
                hoverBorderWidth: 8,
                // borderColor: '#165BAA',
                backgroundColor: ["#63B6C0", "#C5615F"],
                borderWidth: 0,
                borderSkipped: false,
              },
            ],
          },
          _loading1: false,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getDemografiUmur(year) {
    var id_company = localStorage.getItem("Company_ID");
    let _y = year;
    if (_y === null || _y === undefined) {
      _y = moment().year();
    }
    await axios.get(uAPIlocal + '/api/v1/demografiumur', { params: { Company_ID: id_company, _y: _y }, cancelToken: source.token })
      .then(function (response) {
        return response.data;
        // console.log("grafik", response.data);
      })
      .then(response => {
        const grafik = response.grafik;
        console.log("grafik_u", response);
        this.setState({
          grafik2: {
            labels: grafik.kesimpulan,
            // labels: ['Male', 'Female'],
            datasets: [
              {
                label: 'Jumlah',
                data: grafik.jumlah,
                // hoverBackgroundColor: "rgba(255,99,132,0.4)",
                hoverBorderColor: "rgba(0,192,185,255)",
                hoverBorderWidth: 8,
                // borderColor: '#165BAA',
                backgroundColor: ['#63B6C0', '#C5615F', '#30638A', '#D0AD6E'],
                borderWidth: 0,
                borderSkipped: false,
              },
            ],
          },
          _loading2: false,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getDemografiDepartment(year) {
    var id_company = localStorage.getItem("Company_ID");
    let _y = year;
    if (_y === null || _y === undefined) {
      _y = moment().year();
    }
    await axios.get(uAPIlocal + '/api/v1/demografidepartment', { params: { Company_ID: id_company, _y: _y }, cancelToken: source.token })
      .then(function (response) {
        return response.data;
        // console.log("grafik", response.data);
      })
      .then(response => {
        const grafik = response.grafik;
        console.log("grafik_d", response);
        this.setState({
          grafik3: {
            labels: grafik.kesimpulan,
            // labels: ['Male', 'Female'],
            datasets: [
              {
                label: 'Jumlah',
                data: grafik.jumlah,
                // hoverBackgroundColor: "rgba(255,99,132,0.4)",
                hoverBorderColor: "rgba(0,192,185,255)",
                hoverBorderWidth: 8,
                // borderColor: '#165BAA',
                // backgroundColor: ['#63b5c1', '#2f6387', '#dc8f89', '#e4be80', '#e8aabf', '#8e8e8e', '#c1ce99', '#a77356', '#92aab4', '#e8aabf'],
                // backgroundColor: ['#63b5c0', '#2f638a', '#d0ac6e', '#26898e', '#7a3d45', '#c7d2d4', '#565a73', '#dda2be', '#648885', 'rgba(183,183,183,255)'],

                // backgroundColor: ['#08aebe', '#0b556e', '#af1724', '#b7b7b7', '#b1b76d', '#fb3600', '#555a77', '#bb9003', '#008290', '#b6b6b6'],
                // backgroundColor: ['rgba(125,203,146,255)', 'rgba(0,188,101,255)', 'rgba(0,109,79,255)', 'rgba(0,72,65,255)'],
                // backgroundColor: ['#63B6C0', '#C5615F', '#30638A', '#D0AD6E'],
                backgroundColor: ['#63B6C0', '#30638A', '#C5615F', '#D0AD6E', '#6792AC', '#8E8E8E', '#C2CD9C', '#5FA36F', '#A87255', '#71292A'],
                borderWidth: 0,
                borderSkipped: false,
              },
            ],
          },
          _loading3: false,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }




  async getGrafikDash(year) {
    var id_company = localStorage.getItem("Company_ID");
    let _y = year;
    if (_y === null || _y === undefined) {
      _y = moment().year();
    }
    await axios.get(uAPIlocal + '/api/v1/top10pemeriksaan', { params: { Company_ID: id_company, _y: _y }, cancelToken: source.token })
      .then(function (response) {
        return response.data;
        // console.log("grafik_dash", response.data);
      })
      .then(response => {
        const grafik = response.grafik;
        console.log("grafik_dash", grafik);
        this.setState({
          top10TemuanID: grafik.id,
          grafik4: {
            labels: grafik.kesimpulan,
            datasets: [
              {
                label: 'Jumlah',
                data: grafik.jumlah,
                // hoverBackgroundColor: "rgba(255,99,132,0.4)",
                hoverBorderColor: "rgba(0,192,185,255)",
                hoverBorderWidth: 8,
                // backgroundColor: ['#63b5c1', '#2f6387', '#dc8f89', '#e4be80', '#e8aabf', '#8e8e8e', '#c1ce99', '#a77356', '#92aab4', '#e8aabf'],
                // backgroundColor: ['#63b5c0', '#2f638a', '#d0ac6e', '#26898e', '#7a3d45', '#c7d2d4', '#565a73', '#dda2be', '#648885', 'rgba(183,183,183,255)'],
                backgroundColor: ['#63B6C0', '#30638A', '#C5615F', '#D0AD6E', '#6792AC', '#8E8E8E', '#C2CD9C', '#5FA36F', '#A87255', '#71292A'],


                // borderColor: '#165BAA',
                borderWidth: 3,
                borderSkipped: false,

              },

            ]
          },

          options: {
            maintainAspectRatio: false,
            cornerRadius: 10,
            plugins: {
              datalabels: {
                display: false,
                color: "black",
                formatter: Math.round,
                anchor: "end",
                offset: -20,
                align: "start"
              }
            },
            legend: {
              display: false,
              position: "right",
              align: "start",
              fontSize: 22,
              maxSize: {
                "height": 800
              },
              labels: {
                generateLabels: (chart) => {
                  const datasets = chart.data.datasets;
                  return datasets[0].data.map((data, i) => ({
                    text: `${chart.data.labels[i]} :${data}`,
                    fillStyle: datasets[0].backgroundColor[i],
                    index: i
                  }))
                },
                usePointStyle: true,
              },

            },

            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  // display: false,
                },
                gridLines: {
                  borderDash: [12, 8],
                  color: "#D3D3D3",
                  drawBorder: false,
                }

              }],
              xAxes: [{
                ticks: {
                  fontSize: 10,
                  minRotation: 90,
                  display: false,
                },
                gridLines: {
                  display: false
                },
                barThickness: 32,

              }]
            },

          },
          loading2: false,
        })
      })
      .catch(function (error) {
        console.log(error);
      });

    this.get10Temuan1(this.state.selectYear[0]);
  }


  async getNormalAbnormal(year) {
    var id_company = localStorage.getItem("Company_ID");
    let _y = year;
    if (_y === null || _y === undefined) {
      _y = moment().year();
    }
    await axios.get(uAPIlocal + '/api/v1/getnormalabnormal', { params: { Company_ID: id_company, _y: _y }, cancelToken: source.token })
      .then(function (response) {
        return response.data;
        // console.log("grafik_nab", response.data);
      })
      .then(response => {
        const grafik = response.grafik;
        console.log("grafik_nab", grafik);
        this.setState({
          grafik5: {
            // labels: grafik.kesimpulan,
            labels: ['Abnormal', 'Normal'],
            datasets: [
              {
                label: 'Jumlah',
                data: grafik.jumlah,
                // hoverBackgroundColor: "rgba(255,99,132,0.4)",
                hoverBorderColor: "rgba(0,192,185,255)",
                hoverBorderWidth: 8,
                // borderColor: '#165BAA',
                // backgroundColor: ["#62b6c0", "#f4c6c2"], 
                // backgroundColor: ["#63b5c0", "#2f638a"],
                backgroundColor: ["#C5615F", "#63B6C0"],
                borderWidth: 0,
                borderSkipped: false,
              },
            ],
          },
          loading3: false,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  setYearAll = (eventkey, event) => {
    event.persist();
    console.log(eventkey);
    this.getDemografiGender(eventkey);
    this.getDemografiUmur(eventkey);
    this.getDemografiDepartment(eventkey);
    this.getNormalAbnormal(eventkey);
    this.getGrafikDash(eventkey);
    this.get10Temuan1(eventkey)
    this.setState({
      _yAll: eventkey,
      _loading1: true,
      _loading2: true,
      _loading3: true,
      loading2: true,
      loading3: true,
      loading4: true,
    })
  };


  setYear = (eventkey, event) => {
    event.persist();
    console.log(eventkey);
    this.getDemografiGender(eventkey);
    this.getDemografiUmur(eventkey);
    this.getDemografiDepartment(eventkey);
    this.setState({
      _y: eventkey,
      loading: true
    })
  };

  setYear2 = (eventkey, event) => {
    event.persist();
    console.log(eventkey);
    this.getNormalAbnormal(eventkey);
    this.setState({
      _y2: eventkey,
      loading3: true
    })
  };


  setYear3 = (eventkey, event) => {
    event.persist();
    console.log(eventkey);
    this.getGrafikDash(eventkey);
    this.setState({
      _y3: eventkey,
      loading2: true
    })
  };


  setYear4 = (eventkey, event) => {
    event.persist();
    console.log(eventkey);

    this.setState({
      _y4: eventkey,
      loading4: true
    })
  };

  render() {
    const list_year = [
      { key: "2019", value: "2019" },
      { key: "2020", value: "2020" },
      { key: "2021", value: "2021" },
      { key: "2022", value: "2022" },
      { key: "2023", value: "2023" }
    ];

    const _get10TemuanJumlahOrang = this.state._get10TemuanJumlahOrang.sort((a, b) => Number(b.jumlah_orang) - Number(a.jumlah_orang));



    return (
      <>
        <div className='print-div'>
          <div className='print-only' style={{ marginTop: '20px' }}>

            <table style={{ width: '100%', border: '1px solid' }}>

              <tr style={{ border: '1px solid' }}>
                <td>

                  {/* <Row style={{ paddingTop: "0px" }}>

                  <Col md={6}>
                    <h1 className='dashboard13-new'>Demografi {this.state._yAll}</h1>
                  </Col>

                </Row> */}

                  <div className="grid-container" >
                    <div className="grid-item">                        {
                      this.state._loading1 ? (
                        <div className="text-center pd-90" >
                          <Loader type="Bars" color="#0f473e" height={60} width={100} />
                          Loading ...
                        </div>
                      ) : (
                        this.state.grafik ? (
                          <PieChart data={this.state.grafik} title={"Jenis Kelamin"} totalPeserta={this.state.totalPeserta} />
                        ) : null
                      )
                    }</div>
                    <div className="grid-item">                        {
                      this.state._loading2 ? (
                        <div className="text-center pd-90" >
                          <Loader type="Bars" color="#0f473e" height={60} width={100} />
                          Loading ...
                        </div>
                      ) : (
                        this.state.grafik2 ? (
                          <PieChart data={this.state.grafik2} title={"Usia"} />
                        ) : null
                      )
                    }</div>
                    <div className="grid-item">                        {
                      this.state._loading3 ? (
                        <div className="text-center pd-90" >
                          <Loader type="Bars" color="#0f473e" height={60} width={100} />
                          Loading ...
                        </div>
                      ) : (
                        this.state.grafik3 ? (
                          <PieChart data={this.state.grafik3} title={"Departemen"} />
                        ) : null
                      )
                    }</div>
                  </div>
                </td>
              </tr>
              <tr style={{ border: '1px solid' }}>
                <td>

                  <Row style={{ paddingTop: "0px" }}>

                    <Col md={6}>
                      <h1 className='dashboard13-new'>Top 10 Temuan {this.state._yAll}</h1>
                    </Col>

                  </Row>


                  {
                    this.state.loading2 ? (
                      <div className="text-center pd-90" >
                        <Loader type="Bars" color="#0f473e" height={60} width={100} />
                        Loading ...
                      </div>
                    ) : (
                      this.state.grafik4 ? (
                        <Barchart type='bar' id={this.state.top10TemuanID} data={this.state.grafik4} year={this.state._yAll} options={this.state.options} />
                      ) : null
                    )
                  }



                </td>
              </tr>


              <tr style={{ border: '1px solid' }}>
                <td>


                  <Row style={{ paddingTop: "0px" }}>
                    <Col md={12}>
                      <h1 className='dashboard13-new'>Normal / Temuan Abnormal {this.state._yAll}</h1>
                    </Col>
                  </Row>


                  <div className="grid-container" >

                    <div className="grid-item">
                      {this.state.loading3 ? (
                        <div className="text-center pd-90" >
                          <Loader type="Bars" color="#0f473e" height={60} width={100} />
                          Loading ...
                        </div>
                      ) : (
                        this.state.grafik5 ? (
                          <DoughnutChart2 data={this.state.grafik5} />
                        ) : null
                      )
                      }
                    </div>

                  </div>




                </td>
              </tr>
            </table>



            <div className="page-break" />


            <div className="container-fluid">
              <table style={{ width: '100%' }}>
                <tr>
                  <th>Company</th>
                  <th>Contact</th>
                  <th>Country</th>
                </tr>
                <tr>
                  <td>Alfreds Futterkiste</td>
                  <td>Maria Anders</td>
                  <td>Germany</td>
                </tr>
                <tr>
                  <td>Centro comercial Moctezuma</td>
                  <td>Francisco Chang</td>
                  <td>Mexico</td>
                </tr>
                <tr>
                  <td>Ernst Handel</td>
                  <td>Roland Mendel</td>
                  <td>Austria</td>
                </tr>
                <tr>
                  <td>Island Trading</td>
                  <td>Helen Bennett</td>
                  <td>UK</td>
                </tr>
                <tr>
                  <td>Laughing Bacchus Winecellars</td>
                  <td>Yoshi Tannamuri</td>
                  <td>Canada</td>
                </tr>
                <tr>
                  <td>Magazzini Alimentari Riuniti</td>
                  <td>Giovanni Rovelli</td>
                  <td>Italy</td>
                </tr>
              </table>
            </div>


          </div>


        </div>

        {/* <Row style={{ marginBottom: "20px" }}>
                    <Col xl={8} md={12} sm={12} className="dashboardColumn1" >

                        <div className="dashboard1_new">

                            <Row style={{ paddingTop: "0px", marginBottom: "10px", }}>

                                <Col md={6}>
                                    <div id="rectangle-39-new-rsp">DEMOGRAFI</div>
                                </Col>
                                <Col md={6}>

                                    <div className="form-group" >
                                        <DropdownButton
                                            align="start"
                                            id="dropdown-menu-align-end"
                                            onSelect={this.setYearAll}
                                            style={{ float: "right", marginBottom: "10px", marginRight: '20px', marginTop: '10px' }}
                                            title={
                                                <span>
                                                    {this.state._yAll} <img alt='img1' src={dropdownIcon} style={{ width: '12px' }} />
                                                </span>
                                            }

                                        >
                                            {this.state.selectYear.map((item, index) => {
                                                return (
                                                    <Dropdown.Item key={index} className={item === this.state._yAll ? 'dropdown-item-checked' : null} active={item === this.state._yAll ? true : false} eventKey={item}>
                                                        {item}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </DropdownButton>
                                    </div>

                                </Col>
                            </Row>

                            <div className="grid-container" >
                                <div className="grid-item">                        {
                                    this.state._loading1 ? (
                                        <div className="text-center pd-90" >
                                            <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                            Loading ...
                                        </div>
                                    ) : (
                                        this.state.grafik ? (
                                            <PieChart data={this.state.grafik} title={"Jenis Kelamin"} totalPeserta={this.state.totalPeserta} />
                                        ) : null
                                    )
                                }</div>
                                <div className="grid-item">                        {
                                    this.state._loading2 ? (
                                        <div className="text-center pd-90" >
                                            <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                            Loading ...
                                        </div>
                                    ) : (
                                        this.state.grafik2 ? (
                                            <PieChart data={this.state.grafik2} title={"Usia"} />
                                        ) : null
                                    )
                                }</div>
                                <div className="grid-item">                        {
                                    this.state._loading3 ? (
                                        <div className="text-center pd-90" >
                                            <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                            Loading ...
                                        </div>
                                    ) : (
                                        this.state.grafik3 ? (
                                            <PieChart data={this.state.grafik3} title={"Departemen"} />
                                        ) : null
                                    )
                                }</div>
                            </div>



                        </div>


                    </Col>

                    <Col xl={4} md={12} >
                        <div className="dashboard12-new">
                            <Row style={{ paddingTop: "0px" }}>
                                <Col md={12}>
                                    <h1 className='dashboard13-new'>Normal / Temuan Abnormal {this.state._yAll}</h1>
                                </Col>
                            </Row>

                            {this.state.loading3 ? (
                                <div className="text-center pd-90" >
                                    <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                    Loading ...
                                </div>
                            ) : (
                                this.state.grafik5 ? (
                                    <DoughnutChart2 data={this.state.grafik5} />
                                ) : null
                            )
                            }

                        </div>

                    </Col>
                </Row> */}

        {/* <Row style={{ marginBottom: '-20px' }}>
                    <Col xl={8} md={12} className="dashboardColumn1">

                        <div className="dashboard1_new">

                            <Row style={{ paddingTop: "0px" }}>

                                <Col md={6}>
                                    <h1 className='dashboard13-new'>Top 10 Temuan {this.state._yAll}</h1>
                                </Col>

                            </Row>


                            {
                                this.state.loading2 ? (
                                    <div className="text-center pd-90" >
                                        <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                        Loading ...
                                    </div>
                                ) : (
                                    this.state.grafik4 ? (
                                        <Barchart type='bar' id={this.state.top10TemuanID} data={this.state.grafik4} year={this.state._yAll} options={this.state.options} />
                                    ) : null
                                )
                            }

                        </div>


                    </Col>

                    <Col xl={4} md={12} id='normaltemuan'>
                        
                    <div className="dashboard12-new">

                        <Row style={{ paddingTop: "0px" }}>

                            <Col md={6}>
                                <h1 className='dashboard13-new'>Temuan {this.state._yAll}</h1>
                            </Col>

                        </Row>



                        <div className='grafik1-new'>
                            {
                                this.state.loading4 ? (
                                    <div className="text-center pd-90" >
                                        <Loader type="Bars" color="#0f473e" height={60} width={100} />
                                        Loading ...
                                    </div>
                                ) : (
                                    this.state._get10TemuanJumlahOrang ? (


                                        <Swiper
                                            style={{ "--swiper-navigation-color": "#FFF", "--swiper-pagination-color": "#FFF" }}

                                            id="swiper"
                                            navigation={{
                                                nextEl: '.swiper-button-next',
                                                prevEl: '.swiper-button-prev',
                                            }}
                                            modules={[Navigation]}
                                            className="mySwiper">

                                            {_get10TemuanJumlahOrang.map((item, index) => {
                                                return (

                                                    <SwiperSlide key={index}>
                                                        <Row style={{
                                                            paddingTop: "0px",
                                                            backgroundColor: "#0F473E",
                                                            width: '100%',
                                                            height: '100%',
                                                            margin: '0px',
                                                            borderRadius: '20px'
                                                        }}>

                                                            <Col md={12}>
                                                                <p className="sw-text1">{this.state._get10TemuanJumlahOrang[index].conc}</p>
                                                            </Col>

                                                            <Col xl={6} md={6} sm={6} xs={12} className="swiperSw">

                                                                <p className="sw-text2">
                                                                    {this.state._get10TemuanJumlahOrang[index].jumlah_orang}
                                                                </p>

                                                            </Col>
                                                            <Col xl={6} md={6} sm={6} xs={12} className="swiperSw">

                                                                <img alt='img3' className={this.state._get10TemuanJumlahOrang[index].status === 'Naik' ? "sw-img1" : "sw-img2"} src={GrowthArrow} style={{ display: this.state._get10TemuanJumlahOrang[index].percentage != '0.0 %' ? null : 'none' }} />
                                                                <p className={this.state._get10TemuanJumlahOrang[index].status === 'Naik' ? "sw-text3" : "sw-text3B"} style={{ display: this.state._get10TemuanJumlahOrang[index].percentage != '0.0 %' ? null : 'none' }}>{this.state._get10TemuanJumlahOrang[index].percentage}</p>

                                                            </Col>

                                                            <Col md={6}>

                                                                <p className="sw-text4">Age : {this.state._get10TemuanJumlahOrang[index].Patient_Age_Range}</p>
                                                            </Col>
                                                            <Col md={6} >
                                                                <p className="sw-text4">Male: {this.state._get10TemuanJumlahOrang[index].total_orang_laki}</p>
                                                                <p className="sw-text4"> Female: {this.state._get10TemuanJumlahOrang[index].total_orang_perempuan}</p>
                                                            </Col>

                                                        </Row>

                                                    </SwiperSlide>


                                                );
                                            })}

                                        </Swiper>


                                    ) : null
                                )
                            }




                        </div>
                        <i className="swiper-button-next"></i>
                        <i className="swiper-button-prev"></i>



                        </div>

                    </Col>
                </Row> */}





      </>

    )
  }
}
