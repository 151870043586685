import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
// import "./assets/login.scss";
import Loader from "react-loader-spinner";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { uAPIlocal } from './lib/config';
import queryString from "query-string";
import moment from "moment";
import Swal from "sweetalert2";
// import { useCookies } from "react-cookie";
import './LoginPage.css';
import frame2 from "../assets/frame2.svg";
import logo from "../assets/logo.svg";
import frame1 from "../assets/frame1.svg"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export default function Login() {
  const location = useHistory().location;
  const query = queryString.parse(location.search);
  const [token, setToken] = useState(false);
  const [valid, setValid] = React.useState(true);
  const [statusReset, setStatusReset] = React.useState(false);

  const instance = axios.create({
    withCredentials: true,
    // withCredentials: false,
  });

  //  const [cookies, setCookie] = useCookies();

  const [hidden, setHidden] = React.useState(true);
  const [loader, setLoader] = React.useState(false);

  const [recentUser, setRecentUser] = React.useState();
  const [CSRFToken, setCSRFToken] = React.useState();

  const INITIAL_STATE = {
    new_password: "",
    new_passwordRe: "",
    email_user: "",
  };

  const [state, setState] = React.useState(INITIAL_STATE);
  const { new_password, new_passwordRe, email_user } = state;

  const loginError = async (msg) => {
    await swalWithBootstrapButtons
      .fire({
        icon: "error",
        title: msg,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      })
      .then(function () {
        setLoader(false);
      });
  };

  const loginSuccess = async (msg) => {
    await swalWithBootstrapButtons
      .fire({
        icon: "success",
        title: msg,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      })
      .then(function () {
        setLoader(false);
      });
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  function onClose_() {
    setLoader(false);
    // setLogin(true);
  }

  function toggleShow() {
    setHidden(!hidden);
  }

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {

    getCSRFToken();

    if (token === false) {
      checkToken(query.token);
      setToken(true);
    }
  });

  const getCSRFToken = async () => {
    await instance
      .get(uAPIlocal + "/getCSRFToken")
      .then((response) => {
        // console.log(response);
        // setCSRFToken(response.data.CSRFToken);
        // sendToken();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendToken = async (csrf) => {
    //  console.log(document.cookie);
    // console.log(cookies);

    await instance({
      method: "post",
      url: uAPIlocal + "/api/v1/auth_csrf",
    })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function checkToken(key) {
    await instance
      .post(uAPIlocal + "/api/v1/auth_token", {
        token: key,
      })
      .then((response) => {
        console.log("hasil token",response.data.results);
        setState({
          ...state,
          ["email_user"]: response.data.results[0].email,
        });
        if (
          moment(response.data.results[0].created_date).format("YYYY-MM-DD") !=
          moment().format("YYYY-MM-DD") ||
          response.data.results[0].status === 1
        ) {
          setValid(false);
        }
      });
  }

  async function updatePassword(e) {
    e.preventDefault();
    await setLoader(true);
    console.log(email_user);
    if (new_password == new_passwordRe) {
      if (new_password.length < 6) {
        loginError("Kata sandi minimal harus 6 karakter");
      } else {
        await instance
          .post(uAPIlocal + "/api/v1/update_password", {
            email_user: email_user,
            new_password: new_password,
          })
          .then((response) => {
            console.log("response", response);
            return response.data;
          })
          .then((JsonData) => {
            const status = JsonData.status;
            if (status !== "200") {
              loginError("Terjadi Kesalahan!");
            } else {
              updateStatusReset();
            }
          })
          .catch((error) => {
            loginError("Terjadi Kesalahan!");
          });
      }
    } else {
      await loginError("Password tidak sama!");
    }
  }

  async function updateStatusReset() {
    await instance
      .post(uAPIlocal + "/api/v1/update_statusReset", {
        token: query.token,
      })
      .then((response) => {
        setTimeout(() => {
          setStatusReset(true);
        }, 3000);
        loginSuccess("Password berhasil diubah!");
      })
      .catch((error) => {
        loginError("Terjadi Kesalahan!");
      });
  }

  if (valid === false) {
    return (
      <Redirect
        to={{
          pathname: "/",
          login_alert: {
            msg: "Token telah kadaluarsa! Silahkan ajukan reset password kembali.",
          },
        }}
      />
    );
  } else if (statusReset) {
    return (
      <Redirect
        to={{
          pathname: "/",
          status: "logout",
          login_alert: {
            msg: "Silahkan Login Kembali!",
          },
        }}
      />
    );
  }

  //  else if (cookies["_login"] != null) {
  //   return (
  //    <Redirect
  //     to={{
  //      pathname: "/registration",
  //      state: {
  //       detail_login: JSON.parse(localStorage.getItem("_log")),
  //      },
  //     }}
  //    />
  //   );
  //  } 
  else {
  }

  return (
    <>


      <div className="Auth-form-container">
        {/* <img alt='image1' className="frame-2-new" src={frame2} /> */}
        <form className="Auth-form" onSubmit={updatePassword}>

          <div className="Auth-form-content">


            <Row>

              <div className="col-12 d-flex justify-content-center text-center" style={{ marginBottom: '20px' }}>
                {/* <img alt='image2' className="logo-new" src={logo} /> */}
              </div>


            </Row>

            {/* <h3 className="Auth-form-title">Sign In</h3> */}
            <div className="form-group mt-3 icon-div">
              <label className="label-new">New Password</label>
              <input
                className="num-09-input-field-instance-new"
                //  placeholder="************"
                type={hidden ? "password" : "text"}
                name="new_password"
                id="myInput"
                onChange={onChange}
                autoComplete="off"
              />

              <a href="#" className="psw_child" onClick={toggleShow}>
                {hidden === true ? (
                  <i className="fa fa-eye"></i>
                ) : (
                  <i className="fa fa-eye-slash"></i>
                )}
              </a>
            </div>
            <div className="form-group mt-3 icon-div">
              <label className="label-new">Re-Type New Password</label>
              <input
                className="num-09-input-field-instance-new"

                //  placeholder="************"
                type={hidden ? "password" : "text"}
                name="new_passwordRe"
                id="myInput"
                onChange={onChange}
                autoComplete="off"
              />






            </div>
            <div className="d-grid gap-2 mt-3">

              {state.loader ? (
                <Loader type="Circles" color="#00BFFF" height={40} width={100} />
              ) : (
                <button type="Reset Password" className="num-03-filled-button-instance-1-new"><p className='fontlogin'>Log In</p></button>
              )}


            </div>
          </div>
        </form>
        {/* <img alt='image12' className="frame-1-new" src={frame1} /> */}
      </div>


      {/* <div className="container-fluid">
        <div
          className="row no-gutter"
          style={{ maxWidth: "800px", margin: "auto" }}
        >
          <div className="col-md-12">
            <div className="login d-flex align-items-center py-4">
              <div className="container">
                <div className="row">
                  <div
                    className="col"
                    style={{
                      marginTop: 120,
                      marginLeft: 50,
                      marginRight: 50,
                    }}
                  >
                    <p
                      style={{
                        marginRight: "10px",
                        fontFamily: "var(--font-family-gotha-bol)",
                        fontSize: 18,
                      }}
                    >
                      Reset Password
                    </p>
                    <form onSubmit={updatePassword}>
                      <div className="form-group mb-3">
                        <div className="text-center d-flex justify-content-between mt-4">
                          <p className="input_label">New Password</p>
                        </div>
                        <input
                          //  className="form-control rounded-pill border-0 px-4"
                          style={{
                            background: "#CCD9DD",
                            fontFamily: "var(--font-family-gotha-boo)",
                            fontSize: 14,
                            fontWeight: "bold",
                            boxShadow: "0px 4px 7px #8989",
                            border: 0,
                            transition: "0.6s ease",
                          }}
                          //  placeholder="************"
                          type={hidden ? "password" : "text"}
                          name="new_password"
                          id="myInput"
                          onChange={onChange}
                          autoComplete="off"
                        />
                      </div>

                      <div className="form-group mb-3">
                        <div className="text-center d-flex justify-content-between mt-4">
                          <p className="input_label">Re-Type New Password</p>
                        </div>
                        <input
                          //  className="form-control rounded-pill border-0 px-4"
                          style={{
                            background: "#CCD9DD",
                            fontFamily: "var(--font-family-gotha-boo)",
                            fontSize: 14,
                            fontWeight: "bold",
                            boxShadow: "0px 4px 7px #8989",
                            border: 0,
                            transition: "0.6s ease",
                          }}
                          //  placeholder="************"
                          type={hidden ? "password" : "text"}
                          name="new_passwordRe"
                          id="myInput"
                          onChange={onChange}
                          autoComplete="off"
                        />
                      </div>

                      <div>
                        <div className="submit-button">
                          {state.loader ? (
                            <Loader type="Circles" color="#00BFFF" height={40} width={100} />
                          ) : (
                            <div className="accordion-reset">
                              <input type="submit" name="" value="Reset Password" />
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <ToastContainer
    position="bottom-right"
    autoClose={1000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
   /> */}
    </>
  );
}
