import React, { Component } from 'react';
import { LinkButton } from 'rc-easyui';
import XLSX from 'xlsx';
import axios from 'axios';
import { uAPIlocal } from '../../lib/config';
import fileSaver from 'file-saver';
import { Bar } from 'react-chartjs-2';


class Export extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataKesimpulan: {
                kesimpulan: [],
                a: [],
                b: [],
                c: [],
                d:[]
            }
        }
        this.canvasRef = React.createRef();
        this.exportFile = this.exportFile.bind(this);
        this.pasien = this.pasien.bind(this);
        this.hasil = this.hasil.bind(this);
        this.dept = this.dept.bind(this);
        this.kesimpulan = this.kesimpulan.bind(this);
    }
    exportFile(name, data) {
        /* convert state to workbook */
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, name); //nama sheet nya
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, `${name}.xls`)
    };
    async exportFile2(url, name) {
        var id_company = localStorage.getItem("Company_ID");
        await axios.get(uAPIlocal + url, { responseType: 'arraybuffer', id_company:id_company })
            .then(function (response) {
                // console.log(response)
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fileSaver.saveAs(blob, `${name}.xlsx`);
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    async pasien() {
        const name = 'Pasien';
        const url = '/api/v1/export_pasien';
        this.exportFile2(url, name);
    }
    async hasil() {
       
        const name = 'All-Patient';
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        formData.append('Company_ID',localStorage.getItem("Company_ID"));
        await axios.post(uAPIlocal + '/api/v1/result_grafik_all', formData, config)
            .then(function (response) {
                return response.data;
            })
            .then(response => {
                const grafik = response.grafik;
                this.setState({
                    grafik: {
                        labels: grafik.kesimpulan,
                        datasets: [
                            {
                                label: "2023",
                                backgroundColor: 'purple',
                                borderColor: 'rgba(0,0,0,1)',
                                borderWidth: 1,
                                data: grafik.a
                            },
                            {
                                label: "2022",
                                backgroundColor: '#00a850',
                                borderColor: 'rgba(0,0,0,1)',
                                borderWidth: 1,
                                data: grafik.a
                            }, {
                                label: "2021",
                                backgroundColor: '#3468fa',
                                borderColor: 'rgba(0,0,0,1)',
                                borderWidth: 1,
                                data: grafik.b
                            }, {
                                label: "2020",
                                backgroundColor: '#f34423',
                                borderColor: 'rgba(0,0,0,1)',
                                borderWidth: 1,
                                data: grafik.c
                            },
                            {
                                label: "2019",
                                backgroundColor: 'black',
                                borderColor: 'rgba(0,0,0,1)',
                                borderWidth: 1,
                                data: grafik.d
                            }
                        ]
                    },
                    options: {
                        animation: false,
                        layout: {
                            padding: 5
                        },
                        // legend: {
                        //     display: false
                        // },
                        responsive: true,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }],
                            xAxes: [{
                                ticks: {
                                    fontSize: 10,
                                    minRotation: 90
                                }
                            }]
                        }
                    },
                    
                    dataKesimpulan: {
                        kesimpulan: grafik.kesimpulan,
                        a: grafik.a,
                        b: grafik.b,
                        c: grafik.c,
                        d: grafik.d
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });

        await this.setState({
            TotalityGraphic: this.canvasRef.current.chartInstance.toBase64Image()
        })
        var id_company = localStorage.getItem("Company_ID");
        await axios.post(uAPIlocal + '/api/v1/export_all', {
            TotalityGraphic: this.state.TotalityGraphic,
            dataKesimpulan: this.state.dataKesimpulan,
        }, { responseType: 'arraybuffer',params: { Company_ID: id_company  } })
            .then(response => {
                // console.log(response)
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fileSaver.saveAs(blob, `${name}.xlsx`);
                // return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });

        // const url = '/api/v1/export_all';
        // this.exportFile2(url, name);
    }
    async dept() {
        const name = 'Dept';
        const url = '/api/v1/export_dept';
        this.exportFile2(url, name);
    }
    async kesimpulan() {
        const name = 'Kesimpulan';
        const url = '/api/v1/export_kesimpulan';
        this.exportFile2(url, name);
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className='pd-20'>
               
                <LinkButton iconCls="icon-save" style={{ margin: '10px' }} onClick={this.hasil} >EXPORT TO EXCEL REKAPITULASI</LinkButton>
                
               
                {
                    this.state.grafik ? (
                        <div style={{ position: 'absolute', zIndex: '-9999', display: 'block', width: '1292px', height: '646px' }}>
                            <Bar
                                ref={this.canvasRef}
                                data={this.state.grafik}
                                // width={100}
                                // height={100}
                                options={this.state.options}

                            />
                        </div>
                    ) : null
                }
            </div>
        )
    }
}

export default Export;