import React, { Component } from "react";
import './body.scss';
import {
  Container,
  Row,
  Col,
  // Card,
  // Button,
  // ButtonGroup,
  // Form,
  // FormControl,
  // Breadcrumb,
  // Carousel,
  // List,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
// import ListGroup from "react-bootstrap/ListGroup";
import InputGroup from "react-bootstrap/InputGroup";
// import Modal from "react-bootstrap/Modal";
// import Collapse from "react-bootstrap/Collapse";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  // PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
// import filterFactory, {
//   dateFilter,
//   Comparator,
// } from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
  // CSVExport,
} from "react-bootstrap-table2-toolkit";
// import cellEditFactory from "react-bootstrap-table2-editor";
import { withRouter, Redirect } from 'react-router-dom';

// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import Navbar from './Navbar';
// import maskGroup from "../../assets/maskGroup.png";
import dropdownIcon from "../../assets/br_down.png";
// import Group5 from "../../assets/Group 5.png";
// import Group4 from "../../assets/Group 4.png";

import axios from 'axios';
import { uAPIlocal } from '../lib/config';
// import { Layout, LayoutPanel } from 'rc-easyui';
import moment from "moment";
import "./assets/style.css";
import Loader from 'react-loader-spinner';
import Barchart from '../chart/BarChart';
import { withTranslation } from 'react-i18next';
let source = axios.CancelToken.source();
axios.defaults.withCredentials = true;

class Top10 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableTop10Individual: true,
      tableTop10Department: true,
      datas: [],
      datas2: [],
      datas2_: [],
      loading: true,
      loading2: true,
      _yAll: "",
      _y: moment().year(),
      _y2: moment().year(),
      _y3: moment().year(),
      selectYear: [],
      top10TemuanConc_ID: [],

      // nextpage
      redirect: false,
      pathname: "",
      idSelected: "",
      prevState: "",
      prevPage: "",
      goTo: "",
      action: "false",

      // props
      _id: this.props.location.state ? this.props.location.state.id : null,
      _prevPage: this.props.location.state ? this.props.location.state.prevPage : null,
      _prevState: this.props.location.state ? this.props.location.state.prevState : null,
      _goTo: this.props.location.state ? this.props.location.state.goTo : null,

      _getTop10Department: [],
      isSmallScreen: false,
      hideIconUser: false,
      selectedRow: null,

    };
    this.getTop10Individual = this.getTop10Individual.bind(this);
    this.getTop10Department = this.getTop10Department.bind(this);
    this.getGrafikDash = this.getGrafikDash.bind(this);
    this.getSelectYear = this.getSelectYear.bind(this);
    source = axios.CancelToken.source();
    this.resizeScreen = this.resizeScreen.bind(this);
  }
  async componentDidMount() {
    // console.log("detail_login", this.state.detail_login);
    this.props.i18n.changeLanguage(localStorage.getItem("lang"));

    console.log("yamato", this.props.location.state)

    if (this.state._id != null &&
      this.state._prevPage == "people" &&
      this.state._prevState == "back" &&
      this.state._goTo == "top10_peserta") {
      this.setState({ selectedRow: [this.state._id] });
    } else { }

    this.getSelectYear();
    window.addEventListener("resize", this.resizeScreen());
    const formData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      withCredentials: true
    };

    formData.append('id_user', localStorage.getItem("id_user"));
    formData.append('name', localStorage.getItem("name"));
    formData.append('message', "Akses Top 10");


    await axios.post(uAPIlocal + '/api/v1/log', formData, config)
      .then(response => {
        console.log(response)
        if (response.data.status === 406) {
          console.log(response)

        } else {
          console.log(response)
        }

      })
      .catch(function (error) {
        console.log(error);
      });

  }

  componentWillUnmount() {
    if (source) {
      source.cancel("Landing Component got unmounted");
    }
  }

  resizeScreen() {
    if (window.innerWidth <= 1200) {
      this.setState({ isSmallScreen: true });
    } else {
      this.setState({ isSmallScreen: false });
    }

    if (window.innerWidth <= 1280) {
      this.setState({ hideIconUser: true });
    } else {
      this.setState({ hideIconUser: false });
    }

  }

  redirectPeserta(id) {
    this.setState({
      redirect: true,
      pathname: "/admin/people",
      idSelected: id,
      prevState: "top10_peserta",
      prevPage: "top10",
      goTo: ""
    });
  }


  async getSelectYear(year) {
    var id_company = localStorage.getItem("Company_ID");

    await axios.get(uAPIlocal + '/api/v1/getselectyear', { params: { Company_ID: id_company }, cancelToken: source.token })
      .then(function (response) {
        return response.data;
        console.log("y", response.data);
      })
      .then(response => {
        const res = response.results;
        console.log("year", res);

        if (res.length == 0) {
          this.getSelectYear();
        } else {
          res.map((item, index) => {
            this.setState({ selectYear: [...this.state.selectYear, item.mcu_year] })
          })


          console.log("yearX", this.state.selectYear);

          this.getTop10Individual(this.state.selectYear[0]);
          this.getTop10Department(this.state.selectYear[0]);
          this.getGrafikDash(this.state.selectYear[0]);
          this.setState({
            _yAll: this.state.selectYear[0],
            loading: true
          })

        }

      })
      .catch(function (error) {
        console.log(error);
      });



  }

  async getTop10Individual(year) {

    this.setState({ datas: [] })

    var id_company = localStorage.getItem("Company_ID");
    let _y = year;
    if (_y == null || _y == undefined) {
      _y = moment().year();
    }
    // var id_company = '0383';
    await axios.get(uAPIlocal + '/api/v1/top10individual', { params: { Company_ID: id_company, _y: _y }, cancelToken: source.token })
      .then(function (response) {
        return response.data;
      })
      .then(response => {
        console.log("_top10Individual", response.results);

        var groupBy = function (xs, key) {
          return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
        };
        var groubedByTeam = groupBy(response.results, 'Patient_id')
        console.log("groubedByTeam", groubedByTeam);

        var groubedByTeamSort = Object.values(groubedByTeam).sort(function (a, b) {
          return b.length - a.length;
        });

        console.log("groubedByTeamSort", groubedByTeamSort);


        Object.keys(groubedByTeamSort).map(key => {

          if (key < 10) {



            var x = groubedByTeamSort[key];

            console.log("groubedByTeamSortKEY", x[0].Patient_name);

            var namaDept = "";

            if (x[0].Patient_Dept == "") {
              namaDept = "MANAGEMENT";
            } else {
              namaDept = x[0].Patient_Dept;
            }

            this.setState({ datas: [...this.state.datas, { "name": x[0].Patient_name, "dept": namaDept, "temuan": x.length, "Patient_id": x[0].Patient_id }] })

            //    Object.keys(x).map(key => {

            //   console.log("groubedByTeamSortKEY2",x[key]);



            // });


          }




        });


        // this.setState({
        //   datas: response.results
        // })
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  async getTop10Department(year) {

    this.setState({ datas2: [] })

    var id_company = localStorage.getItem("Company_ID");
    let _y = year;
    if (_y == null || _y == undefined) {
      _y = moment().year();
    }
    // var id_company = '0383';
    await axios.get(uAPIlocal + '/api/v1/top10department', { params: { Company_ID: id_company, _y: _y }, cancelToken: source.token })
      .then(function (response) {
        return response.data;
      })
      .then(response => {
        console.log("mydatatop10Department", response.results);

        const res = response.results;
        this.setState({ _getTop10Department: res }, () =>
          this.getTop10Department2(this.state._yAll)
        );

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  async getTop10Department2(year) {
    var id_company = localStorage.getItem("Company_ID");
    let _y = year;
    if (_y == null || _y == undefined) {
      _y = moment().year();
    }

    this.state._getTop10Department.map((item, index) => {

      axios.get(uAPIlocal + '/api/v1/top10temuandepartment', { params: { Company_ID: id_company, _y: _y, _dept: item.Patient_Dept }, cancelToken: source.token })
        .then(function (response) {
          return response.data;
        })
        .then(response => {
          const res = response.results;

           console.log("_top10temuandepartment", res[0]);

          var percentage = (res[0].jumlah_temuan_peserta / res[0].jumlah_peserta) * 100;
           console.log("percentage", percentage.toFixed(1));
          var percentageText = percentage.toFixed(1) + " %";

          var namaDept = "";

          if (item.Patient_Dept == "") {
            namaDept = "Management";
          } else {
            namaDept = item.Patient_Dept;
          }

          this.setState({ datas2: [...this.state.datas2, { "dept": namaDept, "jumlah_peserta": res[0].jumlah_peserta, "jumlah_temuan_peserta": res[0].jumlah_temuan_peserta, "percentage": percentageText }] })

          // if (index == 9) {
          //   this.setState({ datas2_: this.state.datas2, loading2: false })
          // }
          this.setState({ datas2_: this.state.datas2, loading2: false })

        })
        .catch(function (error) {
          console.log(error);
        });


      //  alert(item.Conc_ID);

    })



  }



  async getGrafikDash(year) {
    var id_company = localStorage.getItem("Company_ID");
    let _y = year;
    if (_y == null || _y == undefined) {
      _y = moment().year();
    }
    await axios.get(uAPIlocal + '/api/v1/top10pemeriksaan', { params: { Company_ID: id_company, _y: _y }, cancelToken: source.token })
      .then(function (response) {
        return response.data;
        // console.log("grafik", response.data);
      })
      .then(response => {
        const grafik = response.grafik;
        console.log("grafik_dash", grafik);
        this.setState({
          top10TemuanConc_ID: grafik.id,
          grafik: {
            labels: grafik.kesimpulan,
            datasets: [
              {
                label: 'Jumlah',
                data: grafik.jumlah,
                // hoverBackgroundColor: "rgba(255,99,132,0.4)",
                hoverBorderColor: "rgba(0,192,185,255)",
                hoverBorderWidth: 8,
                // backgroundColor: ['#63b5c1', '#2f6387', '#dc8f89', '#e4be80', '#e8aabf', '#8e8e8e', '#c1ce99', '#a77356', '#92aab4', '#e8aabf'],
                // backgroundColor: ['#63b5c0', '#2f638a', '#d0ac6e', '#26898e', '#7a3d45', '#c7d2d4', '#565a73', '#dda2be', '#648885', 'rgba(183,183,183,255)'],
                backgroundColor: ['#63B6C0', '#30638A', '#C5615F', '#D0AD6E', '#6792AC', '#8E8E8E', '#C2CD9C', '#5FA36F', '#A87255', '#71292A'],


                // borderColor: '#165BAA',
                borderWidth: 3,
                borderSkipped: false,

              },

            ]
          },

          options: {
            maintainAspectRatio: false,
            cornerRadius: 10,
            plugins: {
              datalabels: {
                display: false,
                color: "black",
                formatter: Math.round,
                anchor: "end",
                offset: -20,
                align: "start"
              }
            },
            legend: {
              display: false,
              position: "right",
              align: "start",
              fontSize: 22,
              maxSize: {
                "height": 800
              },
              labels: {
                generateLabels: (chart) => {
                  const datasets = chart.data.datasets;
                  return datasets[0].data.map((data, i) => ({
                    text: `${chart.data.labels[i]} :${data}`,
                    fillStyle: datasets[0].backgroundColor[i],
                    index: i
                  }))
                },
                usePointStyle: true,
              },
            },

            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  // display: false,
                },
                gridLines: {
                  borderDash: [12, 8],
                  color: "#D3D3D3",
                  drawBorder: false,
                }

              }],
              xAxes: [{
                ticks: {
                  fontSize: 10,
                  minRotation: 90,
                  display: false,
                },
                gridLines: {
                  display: false
                },
                barThickness: 32,

              }]
            },

          },
          loading: false,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }




  actFormatter = (cell, row) => {
    const onclick = (event) => {
      // console.log("clicked on row ", row);
      this.handleShowModalDetail();
    };
    return (
      <ul className="list-inline m-0 d-flex justify-content-center">
        <li className="list-inline-item">
          <button
            className="btn btn-primary btn-sm rounded-0"
            type="button"
            data-toggle="tooltip"
            data-placement="top"
            title="View"
            onClick={onclick}
          >
            <i className="fa fa-list" />
          </button>
        </li>
        <li className="list-inline-item">
          <button
            className="btn btn-success btn-sm rounded-0"
            type="button"
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa fa-edit" />
          </button>
        </li>
        <li className="list-inline-item">
          <button
            className="btn btn-danger btn-sm rounded-0"
            type="button"
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i className="fa fa-trash" />
          </button>
        </li>
      </ul>
    );
  };

  imgFormatter(cell, row) {
    return (
      <div className="rounded-corners-users"></div>
    );
  }

  btnFormatter = (cell, row, rowIndex, columnIndex) => {
    const onclick = (event) => {
      this.redirectPeserta(row.Patient_id);
      console.log("clicked on row ", row.Patient_id);
    };

    if (rowIndex % 2 == 0) {
      return (
        <button type="submit" onClick={onclick} className="btn-cirlce2"></button>
      );
    } else {
      return (
        <button type="submit" onClick={onclick} className="btn-cirlce1"></button>
      );
    }

  };


  setYearAll = (eventkey, event) => {
    event.persist();
    console.log(eventkey);
    this.getTop10Individual(eventkey);
    this.getTop10Department(eventkey);
    this.getGrafikDash(eventkey);
    this.setState({
      _yAll: eventkey,
      loading: true
    })
  };


  setYear = (eventkey, event) => {
    event.persist();
    console.log(eventkey);
    this.getTop10Individual(eventkey);
    this.setState({
      _y: eventkey
    })
  };

  setYear2 = (eventkey, event) => {
    event.persist();
    console.log(eventkey);
    this.getTop10Department(eventkey);
    this.setState({
      _y2: eventkey
    })
  };

  setYear3 = (eventkey, event) => {
    event.persist();
    console.log(eventkey);
    this.getGrafikDash(eventkey);
    this.setState({
      _y3: eventkey,
      loading: true
    })
  };

  render() {

    if (this.state.redirect === true) {
      return (<Redirect to={{
        pathname: this.state.pathname,
        state: {
          id: this.state.idSelected,
          prevPage: this.state.prevPage,
          prevState: this.state.prevState,
          goTo: "",
          year_selected: this.state._yAll,
        }
      }} />)
    }

    const { tableTop10Individual, tableTop10Department } = this.state;
    const { SearchBar } = Search;

    const columns = [
      {
        dataField: "Patient_id",
        text: "",
        formatter: this.imgFormatter,
        style: {
          // height: '132px',
          textAlign: 'center',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '120px',
          minWidth: '2%'
          // display: this.state.hideIconUser == true ? 'none' : 'null'
        },
        headerAttrs: {
          // hidden: this.state.hideIconUser == true ? true : false
        },
        headerStyle: (colum, colIndex) => {
          return { borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
        },
      },
      {
        dataField: "Patient_name",
        text: this.props.t('nama'),
        sort: false,
        style: {
          // height: '132px',
          textAlign: 'center',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          maxWidth: '60px',
        },
        headerAttrs: {
          hidden: false
        },
        headerStyle: (colum, colIndex) => {
          return { maxWidth: '60px', borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
        },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (<div><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{row.name}</p></div>);
        },
      },
      {
        dataField: "dept",
        text: this.props.t('departemen'),
        style: {
          // height: '132px',
          textAlign: 'center',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          maxWidth: '80px',
        },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (<div><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{row.dept}</p></div>);
        },
        sort: false,
        headerAttrs: {
          hidden: false
        },
        headerStyle: (colum, colIndex) => {
          return { borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
        },
      },
      {
        dataField: "Temuan",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (<p style={{ color: 'red', fontWeight: 'bold' }}>{row.temuan}</p>);
        },
        style: {
          // height: '132px',
          textAlign: 'center',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
          maxWidth: '40px',
        },
        text: this.props.t('temuan'),
        sort: false,
        headerAttrs: {
          hidden: false
        },
        headerStyle: (colum, colIndex) => {
          return { maxWidth: '40px', borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
        },
      },

      // {
      //   dataField: "Patient_id",
      //   formatter: this.btnFormatter,
      //   style: {
      //     // height: '132px',
      //     textAlign: 'center',
      //     fontWeight: 'normal',
      //     margin: '2px auto',
      //     fontSize: '12px',
      //   },
      //   text: "",
      //   sort: false,
      //   headerAttrs: {
      //     hidden: false
      //   },
      //   headerStyle: (colum, colIndex) => {
      //     return { borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
      //   },
      // },

    ];


    const columns2 = [
      {
        dataField: "dept",
        text: this.props.t('departemen'),
        sort: false,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (<p style={{ fontWeight: 'bold' }}>{row.dept.substring(0, 40).toUpperCase()}{row.dept.length > 40 ? "..." : null}</p>);
        },
        style: {
          // height: '75px',
          textAlign: 'center',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
        },
        headerAttrs: {
          hidden: false
        },
        headerStyle: (colum, colIndex) => {
          return { borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
        },
      },
      {
        dataField: "jumlah_peserta",
        text: this.props.t('temuan') + '(' + this.props.t('orang') + ')',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (<p style={{ color: 'red' }}>{row.jumlah_temuan_peserta}</p>);
        },
        sort: false,
        style: {
          // height: '75px',
          textAlign: 'center',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
        },

        headerAttrs: {
          hidden: false
        },
        headerStyle: (colum, colIndex) => {
          return { borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
        },
      },
      {
        dataField: "jumlah_peserta",
        text: "Total Peserta",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (<p style={{ color: '#0F473E' }}>{row.jumlah_peserta}</p>);
        },
        sort: false,
        style: {
          // height: '75px',
          textAlign: 'center',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
        },
        headerAttrs: {
          hidden: false
        },
        headerStyle: (colum, colIndex) => {
          return { borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
        },
      },
      {
        dataField: "Patient_Dept",
        text: "Persentase",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (<p style={{ color: 'red', fontWeight: 'bold' }}>{row.percentage}</p>);
        },
        style: {
          // height: '80px', 
          textAlign: 'center',
          fontWeight: 'normal',
          margin: '2px auto',
          fontSize: '12px',
        },
        sort: false,
        headerAttrs: {
          hidden: false
        },
        headerStyle: (colum, colIndex) => {
          return { borderRadius: '0px', backgroundColor: "#EFEFEF", fontWeight: '400', fontSize: '12px', textTransform: 'capitalize', padding: '8px', margin: '0px', height: '10px', textAlign: 'center', color: "#000" };
        },
      },


    ];

    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      // totalSize: this.state.datas.length,
    };

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        console.log(e);
        switch (e.detail) {
          case 1:
            console.log("click");
            this.redirectPeserta(row.Patient_id);
            break;
          case 2:
            // this.handleShowModalDetail();
            console.log("double click");
            break;
          case 3:
            console.log("triple click");
            break;
        }
      },
      onMouseEnter: (e, row, rowIndex) => {
        console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    const MySearch = (props) => {
      let input;
      const handleClick = () => {
        props.onSearch(input.value);
      };
      return (
        <div>
          <InputGroup className="mb-3">
            {/* <input
              className="form-control border-right-0 border"
              style={{ backgroundColor: "white", padding: 0 }}
              ref={(n) => (input = n)}
              type="text"
            />
            <button
              className="btn btn-tropical-rain-forest"
              onClick={handleClick}
            >
              Search
            </button> */}

            <div className="col-12">
              <div id="custom-search-input">
                <div className="input-group" style={{ width: '400px' }}>
                  <input ref={(n) => (input = n)} onChange={handleClick} type="text" className="search-query form-control" placeholder="   Search" />
                  <span className="input-group-btn">
                    <button type="button" disabled>
                      <span className="fa fa-search"></span>
                    </button>
                  </span>
                </div>
              </div>
            </div>

          </InputGroup>
        </div>
      );
    };

    const contentTableTop10Individual = ({ paginationProps, paginationTableProps }) => (
      <div style={{ marginLeft: "20px", marginRight: "20px", marginTop: '0px' }}>
        <ToolkitProvider keyField="Patient_id" columns={columns} data={this.state.datas} search>
          {(toolkitprops) => (
            <div>

              <BootstrapTable
                striped
                bordered={false}
                hover
                {...toolkitprops.baseProps}
                {...paginationTableProps}
                rowEvents={tableRowEvents}
                // pagination={ paginationFactory() }
                wrapperClasses="table-responsive"
                selectRow={selectRow}
              />

              {/* <PaginationListStandalone
                style={{ float: "right" }}
                {...paginationProps}
              />*/}
            </div>
          )}
        </ToolkitProvider>
      </div>
    );


    const contentTableTop10Department = ({ paginationProps, paginationTableProps }) => (
      <div style={{ marginLeft: "40px", marginRight: "40px", marginTop: '0px' }}>
        <ToolkitProvider keyField="id" columns={columns2} data={this.state.datas2_.filter(function (s, i) { return i < 10 }).sort((a, b) => Number(b.jumlah_temuan_peserta) - Number(a.jumlah_temuan_peserta))} search>
          {(toolkitprops) => (
            <div>

              {/*
              <p style={{ float: "left", fontSize: "16px", fontWeight: "bold", fontFamily: 'Montserrat' }}>Top 10 Peserta dengan temuan terbanyak</p>

              
              <MySearch {...toolkitprops.searchProps} />

               <Row>
                <Col>
                  <ButtonGroup aria-label="Basic example">
                    <button className="btn">
                      <i className="fa fa-rotate-right" /> Refresh
                    </button>
                    <button className="btn">
                      <i className="fa fa-trash" /> Trash
                    </button>
                  </ButtonGroup>
                </Col>
                <Col>
                  <PaginationListStandalone
                    style={{ float: "right" }}
                    {...paginationProps}
                  />
                </Col>
              </Row> */}

              <BootstrapTable
                striped
                bordered={false}
                hover
                // selectRow={selectRow}
                {...toolkitprops.baseProps}
                {...paginationTableProps}
                // rowEvents={tableRowEvents}
                wrapperClasses="table-responsive"
              // pagination={ paginationFactory() }
              />

              {/* <PaginationListStandalone
                style={{ float: "right" }}
                {...paginationProps}
              />*/}
            </div>
          )}
        </ToolkitProvider>
      </div>
    );


    const selectRow = {
      mode: 'radio',
      // style: { backgroundColor: '#c8e6c9', borderWidth: '2px', borderColor:'#0f7051' },
      classes: 'selection-row',
      clickToSelect: true,
      selected: this.state.selectedRow != null ? this.state.selectedRow : null,
      hideSelectColumn: true,
    };

    return (
      <>

        <Row>
          <Col xl={8} md={12} sm={12} className="top10Column1">

            <div className="dashboard1_new">
              <Row style={{ paddingTop: "0px", marginBottom: "10px" }}>
                <Col md={6}>
                  <h1 className='dashboard13-new'>{this.props.t('top10temuan')}</h1>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <DropdownButton
                      align="start"
                      id="dropdown-menu-align-end"
                      onSelect={this.setYearAll}
                      style={{ float: "right", marginBottom: "10px", marginRight: '20px', marginTop: '10px' }}
                      title={
                        <span>
                          {this.state._yAll} <img alt='img1' src={dropdownIcon} />
                        </span>
                      }

                    >
                      {this.state.selectYear.map((item, index) => {
                        return (
                          <Dropdown.Item key={index} className={item == this.state._yAll ? 'dropdown-item-checked' : null} active={item == this.state._yAll ? true : false} eventKey={item}>
                            {item}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </Col>
              </Row>


              {
                this.state.loading ? (
                  <div className="text-center pd-90" >
                    <Loader type="Bars" color="#0f473e" height={60} width={100} />
                    Loading ...
                  </div>
                ) : (
                  this.state.grafik ? (
                    <Barchart type='bar' id={this.state.top10TemuanConc_ID} year={this.state._yAll} data={this.state.grafik} options={this.state.options} />
                  ) : null
                )
              }

              <div style={{ display: 'flex' }}>
                <div className="box_1"></div>
                <div className="box"></div>
                <div className="box_2"></div>
              </div>




              <Row style={{ paddingTop: "0px", marginTop: '-40px' }}>
                <Col md={12}>
                  <h1 className='dashboard13-new'>{this.props.t('top10departemen')}</h1>
                </Col>
                <Col md={12}>
                  <p className='dashboard13-new' style={{ marginTop: '-8px', fontStyle: 'italic', fontSize: '14px', fontWeight: 'normal' }}>({this.props.t('dengantemuanterbanyak')})</p>
                </Col>
              </Row>


              <div className="top10SubColumn3" style={{ overflowY: 'scroll' }}>
              

                {
                  this.state.loading2 ? (
                    <div className="text-center pd-90" >
                      <Loader type="Bars" color="#0f473e" height={60} width={100} />
                      Loading ...
                    </div>
                  ) : (<PaginationProvider pagination={paginationFactory(options)}>
                    {contentTableTop10Department}
                  </PaginationProvider>
                  )
                }

                {/* <Col md={12} style={{ marginBottom: '20px' }}>
                  <div className="d-flex justify-content-center" style={{ marginTop: '10px', marginLeft: '30px', marginRight: '30px', backgroundColor: 'white', borderRadius: '20px', textAlign: "justify", paddingBottom: '0px', paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
                    <Row>

                      <Col md={12}>
                        <p style={{ fontSize: '12px', fontFamily: "Montserrat", marginBottom: '0px' }}><b>Notes:</b></p>
                      </Col>
                      <Col md={12}>
                        <p style={{ fontSize: '12px', fontFamily: "Montserrat" }}>10 departemen dengan temuan peserta terbanyak, sesuai dengan hasil pemeriksaan</p>
                      </Col>

                    </Row>
                  </div>
                </Col> */}
              </div>
            </div>


          </Col>

          <Col className="top10Column2" xl={4} md={12} sm={12}>
            <div className="dashboard1_new">

              <Row style={{ paddingTop: "0px" }}>
                <Col md={12}>
                  <h1 className='dashboard13-new'>{this.props.t('top10peserta')}</h1>
                </Col>
                <Col md={12}>
                  <p className='dashboard13-new' style={{ marginTop: '-8px', fontStyle: 'italic', fontSize: '14px', fontWeight: 'normal' }}>({this.props.t('dengantemuanterbanyak')})</p>
                </Col>
              </Row>


              <div className="top10SubColumn2" style={{ overflowY: 'scroll', cursor: 'pointer' }}>
                {tableTop10Individual ? (
                  <PaginationProvider pagination={paginationFactory(options)}>
                    {contentTableTop10Individual}
                  </PaginationProvider>
                ) : null}

                {/* <Col md={12} style={{ marginBottom: '20px' }}>
                  <div className="d-flex justify-content-center" style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', backgroundColor: 'white', borderRadius: '20px', textAlign: "justify", paddingBottom: '0px', paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
                    <Row>

                      <Col md={12}>
                        <p style={{ fontSize: '12px', fontFamily: "Montserrat", marginBottom: '0px' }}><b>Notes:</b></p>
                      </Col>
                      <Col md={12}>
                        <p style={{ fontSize: '12px', fontFamily: "Montserrat" }}>10 Individual dengan temuan terbanyak sesuai dengan pemeriksaan</p>
                      </Col>

                    </Row>
                  </div>
                </Col> */}
              </div>



            </div>

          </Col>
        </Row>



      </>
    );
  }
}
export default withRouter(withTranslation()(Top10));
